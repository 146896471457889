import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Images from '../../../services/server/Images';

import DateService from '../../../helpers/DateService';

import DetailsIcon from "../../../assets/svg/Navigation/Details-inactive.svg";
import CodeIcon from "../../../assets/svg/Navigation/Code-active.svg";
import StatsIcon from "../../../assets/svg/Navigation/Stats-inactive.svg";

function ListItem(props) {

    let navigate = useNavigate();
    const [image, setImage] = useState(null);

    useEffect(() => {

        const fetchImageData = async () => {
            let imageData = await Images.getProjectImage(props.project.image);
            const imageBlob = new Blob([imageData])
            setImage(imageBlob);    
        }
        
        if(props.project.image) {
            fetchImageData();
        }

        return () => {
            setImage(null);
        }

    }, [props])

    const onCodeClick = () => {
        navigate('/create/' + props.project.entityId);
    }

    const onStatsClick = () => {
        props.onStatsClick(props.project);
    }

  return (
     <div className='ProjectListItem'>
        <div className='ListItemDetails'>
            {
                image ?
                <img className="ListItemImage" src={URL.createObjectURL(image)} alt={"Bounce Ball"} />
                :
                <div className="ListItemImage"><h4 className='normal breakWord centerText'>No Image</h4></div>
            }
            <div className='ListItemText'>
                <div className='ListItemTitle'>
                    <h3 className='noMargin normal breakWord centerText'>{props.project.name}</h3>
                </div>
                <div className='ListItemStats'>
                    <h5 className='noMargin normal grey'>{props.project.projectUserPlays ? props.project.projectUserPlays + " " : "No "} {props.project.projectUserPlays ? (props.project.projectUserPlays > 1 || props.project.projectUserPlays === 0) ? "plays" : "play" : "plays"}</h5>
                    <h5 className='noMargin normal grey'>{props.project.projectFavorites ? props.project.projectFavorites : "No"} {props.project.projectFavorites === 1 ? "Favorite" : "Favorites"}</h5>
                    <h5 className='noMargin normal grey'>{DateService.formatRelativeTime(props.project.createdAt)}</h5>
                    </div>
                </div>
            </div>
            <div className='ListItemOptions'>
                <div className='ListItemOption' onClick={onCodeClick}>
                    <img className="Icon" src={CodeIcon} alt="Code Icon"/>
                    <h5 className='noMargin normal'>Code</h5>
                </div>
                <div className='ListItemOption' onClick={onStatsClick}>
                    <img className="Icon" src={StatsIcon} alt="Stats Icon"/>
                    <h5 className='noMargin normal'>Stats</h5>
                </div>
            </div>
        </div>
  )
}

export default ListItem