import React, {useEffect, useState} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import axios from 'axios';

import toastMessage from "../helpers/Toast";
import Validation from "../helpers/Validation"

import Input from "./components/Input";
import LandingPageBackground from './components/entry/LandingPage/LandingPageBackground';

function ResetPassword() {

    let { id } = useParams();
    let navigate = useNavigate();

    useEffect(() => {

        async function fetchData() {            
            await axios.get(process.env.REACT_APP_SERVER_URL + "/users/forgotPasswordToken", {
                headers: {
                    forgotPasswordToken: id
                }
            }).then(async (resp) => {
                if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                    navigate('/entry');
                } else {
                    setUser(resp.data.user);
                }
            })
        }

        fetchData();

    }, [])

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("")
    const [passwordTwo, setPasswordTwo] = useState("")

    const onSubmit = async (e) => {

        e.preventDefault();

        let passwordValid = Validation.passwords(password, passwordTwo);

        if(user && passwordValid) {

            let data = {
                id: user.id,
                password: password,
            }

            axios.patch(process.env.REACT_APP_SERVER_URL + '/users/password', data).then((resp) => {
                if(resp.data.warning) {
                    toastMessage(resp.data.warning, "warning");
                } else if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                    navigate('/entry');
                } else {
                    toastMessage(resp.data.message, "success");
                    navigate('/entry');
                }   
            })
        }
    }

    return (
        <div className='Page central'>
            <LandingPageBackground />
            <form className="PageForm Form" onSubmit={(e) => onSubmit(e)}>
                <h2 className='black tertiaryFont'>Reset Password</h2>
                <div className='fields'>
                    <Input 
                        tabIndex="1" 
                        value={password} 
                        setValue={setPassword} 
                        placeholder={"Enter your Password"} 
                        label={"Password"}
                        secret
                    />
                    <Input 
                        tabIndex="2" 
                        value={passwordTwo} 
                        setValue={setPasswordTwo} 
                        placeholder={"Re-Enter your Password"} 
                        label={"Confirm Password"}
                        secret
                    />
                </div>
                <button 
                    className="button-quart" 
                    onClick={(e) => onSubmit(e)}
                >
                    <h3 className='normal tertiaryFont'>Reset</h3>
                </button>
            </form>
        </div>
    )
}

export default ResetPassword