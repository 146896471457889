

export default class ProjectList {

    static sortProject(projects, filterValues) {
        let projectsVisibility = projects;
        if(filterValues.visibility !== "all")
            projectsVisibility = projects.filter((value) => value.visibility === filterValues.visibility);

        let projectsVariable = null;

        switch (filterValues.sort) {
            case "newest":
                projectsVariable = this.sortNewest(projectsVisibility);
                break;
            case "oldest":
                projectsVariable = this.sortOldest(projectsVisibility);
                break;
            case "mostplayed":
                projectsVariable = this.sortNumberOfPlays(projectsVisibility);
                break;
            case "mostFavorited":
                projectsVariable = this.sortNumberOfFavorites(projectsVisibility);
                break;
            default:
                break;
        }

        return projectsVariable;
    }

    static sortNewest(projects) {
        return projects.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            if(dateA > dateB) {
                return -1;
            }
            if(dateA < dateB) {
                return 1;
            }
            return 0;
        })
    }

    static sortOldest(projects) {
        return projects.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            if(dateA < dateB) {
                return -1;
            }
            if(dateA > dateB) {
                return 1;
            }
            return 0;
        })
    }

    static sortNumberOfPlays(projects) {
        return projects.sort((a, b) => {
            if(a.projectUserPlays > b.projectUserPlays) {
                return -1;
            }
            if(a.projectUserPlays < b.projectUserPlays) {
                return 1;
            }
            return 0;
        })
    }

    static sortNumberOfFavorites(projects) {
        return projects.sort((a, b) => {
            if(a.projectFavorites > b.projectFavorites) {
                return -1;
            }
            if(a.projectFavorites < b.projectFavorites) {
                return 1;
            }
            return 0;
        })
    }
}