import React, {useState, useContext} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../helpers/AuthContext'; 

import toastMessage from "../../../helpers/Toast";
import Validation from '../../../helpers/Validation';

import Modal from "./Modal"
import Input from "../Input";
import LandingPageBackground from "./LandingPage/LandingPageBackground";

import LogoAndTitle from "../../../assets/svg/LogoAndTitle.svg"
import ForgotPassword from './ForgotPassword';
import Cache from "../../../services/Cache";
import Authentication from '../../../services/server/Authentication';

function Login(props) {

    let navigate = useNavigate();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("")
    const [forgotPassword, setForgotPassword] = useState(false);
    const {setAuthState} = useContext(AuthContext);


    async function onSubmit(e) {

        e.preventDefault();

        let idType = true;

        if(id && Validation.email(id, true)) 
            idType = false;

        if(id && password) {

            let data = {}

            if(idType) {
                data = {username: id, email: "", password: password}
            } else {
                data = {username: "", email: id, password: password}
            }

            let loginResponse = await Authentication.login(data);

            if(loginResponse) {
                setAuthState({
                    username: loginResponse.username, 
                    id: loginResponse.id, 
                    status: true, 
                    createdAt: loginResponse.createdAt, 
                    image: loginResponse.image, 
                    subscription: loginResponse.subscription
                });
                Cache.deleteAllCache();
                navigate('/home')
            }
        }
    }

    return (
        <>
            <div className='EntryContentsContainer'>
                <div className='FormTitle'>
                    <h1 className='tertiaryFont centerText'>Welcome Back!</h1>
                </div>
                <form className='Form EntryForm' onSubmit={(e) => onSubmit(e)}>
                    <Input 
                        tabIndex="1" 
                        value={id} 
                        setValue={setId} 
                        placeholder={"Enter your Username or Email"} 
                        label={"Username or Email"}
                    />
                    <Input 
                        tabIndex="2" 
                        value={password} 
                        setValue={setPassword} 
                        placeholder={"Enter your Password"} 
                        secret 
                        action={() => setForgotPassword(true)} 
                        actionText={"Forgot your Password?"}
                        label={"Password"}
                    />
                </form>
            </div>
            <div className='LandingPageFooter'>
                <button className='button-quart' onClick={(e) => onSubmit(e)}>
                    <h2 className='tertiaryFont'>Log In</h2>
                </button>
                <h5 className='underlinedHover normal centerText grey' onClick={() => props.setStep(0)}>Don't Have an Account? Sign Up</h5>
            </div>
            <Modal 
                title={"Forgot Password"} 
                show={forgotPassword} 
                setShow={() => {setForgotPassword(false)}}
                content={<ForgotPassword />}/>
        </>
    )
}

export default Login