import React, {useState} from 'react'

import MenuItem from './MenuItem';
import FileExplorer from './FileExplorer';

// SVGs
import Files from "../../assets/svg/Navigation/Files-inactive.svg";
import Search from "../../assets/svg/Navigation/Search.svg";
import Sketch from "../../assets/svg/Navigation/Sketch.svg";
import ToDo from "../../assets/svg/Navigation/ToDo.svg";

function Menu(props) {

  const [state, setState] = useState(0);

  return (
    <div className='Menu'>
        <MenuItem 
          icon={Files} 
          title={"File Explorer"} 
          onClick={() => state===1 ? setState(0) : setState(1)}
          active={state === 1}
          dropdown
        />
        {state === 1 && <FileExplorer 
                          files={props.scripts} 
                          openFile={(value) => props.openFile(value)}
                          onDeleteFile={(value) => props.onDeleteFile(value)}
                          />
        }
        {/* <MenuItem 
          icon={Search} 
          title={"Search"} 
          onClick={() => setState(3) ? setState(0) : setState(3)}
          active={state === 3}
          dropdown
        /> */}
    </div>
  )
}

export default Menu