import React, {useState} from 'react'

import CommentsSection from "./CommentsSection"

import X from "../../assets/svg/X.svg"
import ProjectInfoBoard from './ProjectInfoBoard';

function Popup(props) {

    const [itemsLength, setItemsLength] = useState(0);

    return (
        <div className='PopUp'>
            <div className='PopUpHeader'>
                <div className='PopUpStart'>
                    <h3>{props.title}</h3>
                    {props.type === "comments" && <h4>{itemsLength}</h4>}
                </div>
                <div className='PopUpEnd'>
                    <img className="Icon-Reduced Clickable" src={X} alt="Exit" onClick={() => props.setDisplay(false)}/>
                </div>
            </div>
            <div className='PopUpContent' style={props.height ? {height: props.height} : {}}>
                { props.type === "comments" && <CommentsSection projectId={props.projectId} setItemsLength={setItemsLength}/>}
                { props.type === "info" && <ProjectInfoBoard title={props.title} thumbnail={props.thumbnail} projectId={props.projectId} createdAt={props.createdAt} developer={props.developer} plays={props.plays}/>}
            </div>
        </div>
    )
}

export default Popup