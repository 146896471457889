import axios from "axios";

import toastMessage from "../../helpers/Toast";

import Cache from "../Cache";

export default class Images {

    static ongoingRequests = new Map();

    static async getUserImage(key) {

        let cacheStatus = Cache.checkCached(key);
        if(cacheStatus) {
            return cacheStatus;
        }

        if (Images.ongoingRequests.has(key)) {
            return new Promise((resolve) => {
                Images.ongoingRequests.get(key).then((data) => {
                    resolve(data);
                    Images.ongoingRequests.delete(key);
                });
            });
        }

        const controller = new AbortController();
        const signal = controller.signal;

        Images.ongoingRequests.set(key, new Promise((resolve, reject) => {
            axios.post(
                process.env.REACT_APP_SERVER_URL + "/images/profilepicture",
                {key: key},
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    },
                    responseType: 'blob',
                    signal, // Add the signal to the axios request
                },
            )
            .then(response => {
                if (response.data && response.data.error) {
                    toastMessage(response.data.error, "error");
                    reject();
                    return;
                }

                Cache.setCache(key, response.data);
                resolve(response.data);
            })
            .catch(() => {
                Images.ongoingRequests.delete(key);
            })
        }));

        return Images.ongoingRequests.get(key);

    }

    static async getProjectImage(key) {

        let cacheStatus = Cache.checkCached(key);
        if(cacheStatus) {
            return cacheStatus;
        }

        if (Images.ongoingRequests.has(key)) {
            return new Promise((resolve) => {
                Images.ongoingRequests.get(key).then((data) => {
                    resolve(data);
                    Images.ongoingRequests.delete(key);
                });
            });
        }

        const controller = new AbortController();
        const signal = controller.signal;

        Images.ongoingRequests.set(key, new Promise((resolve, reject) => {
            axios.post(
                process.env.REACT_APP_SERVER_URL + "/images/projectthumbnail",
                {key: key},
                {
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    },
                    responseType: 'blob',
                    signal, // Add the signal to the axios request
                },
            )
            .then(response => {
                if (response.data && response.data.error) {
                    toastMessage(response.data.error, "error");
                    reject();
                    return;
                }

                Cache.setCache(key, response.data);
                resolve(response.data);
            })
            .catch(() => {
                Images.ongoingRequests.delete(key);
            })
        }));

        return Images.ongoingRequests.get(key);

    }

    static async uploadProfileImage(image) {

        try {

            const data = new FormData();
            data.append('file',image);
    
            let response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/images/profilepicture/upload",
                data,
                { 
                    headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
        
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(e) {
            toastMessage(e.response.data.error, 'error');
        }

    }

    static async uploadThumbnailImage(image) {

        const data = new FormData();
        data.append('file',image);

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/images/thumbnail/upload",
            data,
            { 
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}
