import React, {useEffect} from 'react';
import GoBackIcon from "../assets/svg/Navigation/BackArrow.svg"
import HomeIcon from "../assets/svg/Navigation/Home-active.svg"
import { useNavigate, useLocation } from 'react-router-dom';

const TermsOfService = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    useEffect(() => {   
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f9f9f9",
            zIndex: "150",
            position: 'relative',
            overflowX: "hidden"
        }}>
            <div 
                style={{
                    position: "fixed",
                    backgroundColor: "#f9f9f9",
                    width: "100vw",
                    padding: "2vh 5vw",
                    boxSizing: "border-box"
                }}                  
            >
                {
                    state?.prevUrl ?
                    
                    <img className="Icon-Large Clickable"  src={GoBackIcon} alt="Go Back" onClick={(e) => {
                        e.preventDefault();
                        navigate(state?.prevUrl, { state: state?.goBack });
                    }}/>

                    :

                    <img className="Icon-Large Clickable"  src={HomeIcon} alt="Go Home" onClick={(e) => {
                        e.preventDefault();
                        navigate("/home");
                    }}/>

                }
            </div>
            <div  
            style={{
                fontFamily: "Arial, sans-serif",
                lineHeight: "1.6",
                padding: "0 5vw 5vh 5vw",
                gap: "2vh",
                boxSizing: "border-box"
            }}
            className='DesktopPage'
            >
                <div style={{
                    marginTop: "10vh",
                    marginBottom: "5vh"
                }}>
                    <h1 className='noMargin bold breakWord' style={{color: "#333"}}>Terms of Service</h1>
                    <br />
                    <p className='noMargin breakWord' style={{color: "#555"}}><strong style={{color: "#333"}}>Effective Date: </strong>13/09/2024 </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>1. Acceptance of Terms</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        By accessing or using our services, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>2. Changes to Terms</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        We may update these Terms of Service from time to time. We will notify you of any changes by posting the new Terms of Service on this page. You are advised to review this page periodically for any changes.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>3. User Accounts</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        To access certain features of our services, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>4. User Responsibilities</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        You agree to use our services only for lawful purposes and in accordance with these Terms of Service. You agree not to:
                    </p>
                    <ul>
                        <li style={{color: "#555"}}>Use the services in any way that violates any applicable federal, state, local, or international law or regulation.</li>
                        <li style={{color: "#555"}}>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the services.</li>
                        <li style={{color: "#555"}}>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                    </ul>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>5. Intellectual Property Rights</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        All content, trademarks, and other intellectual property rights in our services are owned by us or our licensors. You are granted a limited, non-exclusive, non-transferable license to use our services for personal, non-commercial use.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>6. Limitation of Liability</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of, or inability to access or use, our services.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>7. Governing Law</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        These Terms of Service shall be governed by and construed in accordance with the laws of the European Union, without regard to its conflict of law provisions.
                    </p>
                </div>

                <div style={{
                    marginBottom: "5vh"
                }}>
                    <h2 className='noMargin breakWord' style={{color: "#333"}}>8. Contact Us</h2>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        If you have any questions about these Terms of Service, please contact us at:
                    </p>
                    <p className='noMargin breakWord' style={{color: "#555"}}>
                        <br />
                        <a
                            href={`mailto:info@wanderbuilds.com`}    
                        >
                            info@wanderbuilds.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
