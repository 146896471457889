import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Authentication {

    static isChecking = false;

    static async check() {

        if (this.isChecking) {
            return; 
        }
        this.isChecking = true; 

        try {

            let token = localStorage.getItem("accessToken");
                    
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + '/users/auth', 
                {
                    headers: {
                        accessToken: token
                    }
                }
            );

            if(response.data && response.data.error) {
                // toastMessage(response.data.error, "error");
                return;
            }

            if(response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
            }

            return response.data;
    
        } catch(error) {
            console.error("Error during check:", error);
        } finally {
            this.isChecking = false;
        }
    }

    static async login(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + '/users/login', 
            data
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }

        if(response.data) {
            toastMessage("Logged In", "success") 
            localStorage.setItem("accessToken", response.data.token)
        }

        return response.data;
    }
}