import React, {useEffect, useState} from 'react'

function ColumnMenu(props) {

    const [active, setActive] = useState(0);

    useEffect(() => {
        if(props.active !== undefined) {
            setActive(props.active);
        }
    }, [props.active])

    const onOptionClick = (key) => {
        setActive(key);
        props.setActive(key);
    }

    return (
        <div className='ColumnMenuContainer'>
            {
                props.options.map((value, key) => {
                    return (
                        <div key={key} className={ active === key ? 'ColumnMenuOption ColumnMenuSelected' : 'ColumnMenuOption'} onClick={() => onOptionClick(key)}>
                            <h3 className={key == 2 ? 'normal' : 'normal'}>{value.name}</h3>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ColumnMenu;