import React, { useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom';

import SignUp from './components/entry/SignUp'
import Login from './components/entry/Login'
import LandingPageBackground from "./components/entry/LandingPage/LandingPageBackground";
import LogoAndTitle from "../assets/svg/LogoAndTitle.svg"
import InstallAppButton from './components/atoms/InstallAppButton';

function Entry() {

  let navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [step, setStep] = useState((state && state.step) ? state.step : 0);

  return (
        <div className='Page Column PageCenter'>
            <LandingPageBackground />
            <InstallAppButton />
            <div className='LandingPageHeader'>
                <div className='SignContainer' onClick={() => navigate("/")}>
                    <img src={LogoAndTitle} alt="Logo" />
                </div>
            </div>
            {
              step === 1 ?
                <Login 
                  setStep={(val) => setStep(val)}
                /> :
                <SignUp 
                  setStep={(val) => setStep(val)}
                />
            }
        </div>
  )
}

export default Entry
