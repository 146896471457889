import React, {useEffect, useState} from 'react'

function Select(props) {

  const [selected, setSelected] = useState("")

  useEffect(() => {
    if(props.selected){
      setSelected(props.selected);
    }
  }, [props.selected])

  const onChange = async (e) => {

    if(props.onChange(e.target.value)) {
      await setSelected(e.target.value);
      await props.setSelected(e.target.value)
    }

  }

  return (
    <select className={"SelectFull"} value={selected} name={props.id} id={props.id} onChange={onChange} disabled={props.disabled}>
        {
            props.options.map((value, key) => {
                return <option key={key} value={value}>{value}</option>
            })
        }
    </select>
  )
}

export default Select