import React, {useState, useEffect } from 'react'

import Header from './Header';
import UserDetails from './atoms/UserDetails';
import ProjectCarousal from './atoms/ProjectCarousal';
import UserSettings from './atoms/UserSettings';
import Loading from './atoms/Loading';

import Projects from '../../services/server/Projects';
import SocialMenu from './atoms/SocialMenu';
import SubscriptionMenu from './atoms/SubscriptionMenu';

function UserPage(props) {

  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    async function fetchData() {
      let response = await Projects.getProjectsFavoritedByUser();
      setProjects(response);
      setIsLoading(false);
    }

    fetchData();
  }, [])

  if(isLoading)
    return <Loading />

  return (
    <>
          <Header 
            onClickIcon={() => props.setNav(0)}
            endIcons={[]}
          />
          <div className="Contents">
            <div className='DesktopPage'>
              <UserDetails />
              <ProjectCarousal projects={projects} isLoading={isLoading}/>
              <SubscriptionMenu />
              <SocialMenu />
              <UserSettings />
            </div>
          </div>
    </>
  )
}

export default UserPage