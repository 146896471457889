import React, {useState} from 'react'

function PricingContainer(props) {

    const [duration, setDuration] = useState("month");

    return (
        <div className='PricingContainer'>
                <div className='PricingHeader'>
                    <h1 className='tertiaryFont textStart'>{props.title}</h1>
                    <h4 className='grey normal noMargin textStart'>{props.subTitle}</h4>
               
                <div style={props.setDuration ? {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                    paddingTop: "1vh"
                } : {
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-around",
                    paddingTop: "1vh",
                    opacity: 0,
                    pointerEvents: "none"
                }}>
                    <div 
                    className='tertiaryFont'
                    style={
                        duration === "month" ? 
                        {
                            backgroundColor: "#FFEC46",
                            color: "black",
                            padding: "1vh 3vw",
                            borderRadius: "1vh",
                            cursor: "pointer"
                        }
                        : 
                        {
                            backgroundColor: "transparent",
                            padding: "1vh 3vw",
                            borderRadius: "1vh",
                            cursor: "pointer"

                        }
                    }
                    onClick={() => {
                        setDuration("month");
                        props.setDuration("month")
                    }
                    }
                    >
                        Monthly
                    </div>
                    <div
                    className='tertiaryFont'
                    style={
                        duration === "year" ? 
                        {
                            backgroundColor: "#FFEC46",
                            color: "black",
                            padding: "1vh 3vw",
                            borderRadius: "1vh",
                            cursor: "pointer"
                        }
                        : 
                        {
                            backgroundColor: "transparent",
                            padding: "1vh 3vw",
                            borderRadius: "1vh",
                            cursor: "pointer"

                        }
                    }
                    onClick={() => {
                            setDuration("year")
                            props.setDuration("year");
                        }
                    }
                    >
                        Yearly
                    </div>
                </div>

                </div>
                <div className='PricingFeatures'>
                    <h3 className='normal tertiaryFont textStart'>Features</h3>
                    <ul className='FeaturesList'>
                        {
                            props.features.map((value, key) => {
                                return (<li key={key}>
                                        <h4 className='normal noMargin grey textStart'>{value.value}</h4>
                                    </li>)
                            })
                        }
                    </ul>
                </div>
                <div className='PricingPrice'>
                    {
                        props.setDuration ?
                        <>  
                            <h1 className='tertiaryFont PriceFont noMargin'>{"€"}{duration === "month" ? "15" : "8.33"}</h1>
                            <span className='tertiaryFont SubPriceFont noMargin'>{duration === "month" ? "per month" : "per month"}</span>
                        </>
                        : 
                        <>
                            <h1 className='tertiaryFont PriceFont noMargin'>{"Free"}</h1>
                        </>
                    }
                </div>
                <div className='PricingButton'>
                    <button 
                        style={props.button.style} 
                        onClick={props.button.onClick} 
                        disabled={props.button.disabled}
                        >
                        <h3 style={props.button.textStyle} className='tertiaryFont normal noMargin white breakWord'>{props.button.text}</h3>
                    </button>
                </div>
        </div>
  )
}

export default PricingContainer