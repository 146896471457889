// src/Carousel.js
import React, { useState } from 'react';
import PricingContainer from './PricingContainer';
import Dropdown from '../../../assets/svg/Dropdown.svg';
import useOrientation from './useOrientation';

function Carousel({slides}) {
    const { isPortrait } = useOrientation();
    const [currentIndex, setCurrentIndex] = useState(isPortrait ? 1 : 0);

    const moveSlide = (direction) => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + direction;
            if (newIndex < 0) return slides.length - 1; // Wrap to last slide
            if (newIndex >= slides.length) return 0; // Wrap to first slide
            return newIndex;
        });
    };

    return (
        <div className="carousel">
            <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {slides.map((slide) => (
                    <PricingContainer 
                        key={slide.id}
                        title={slide.title}
                        value={slide.value}
                        subTitle={slide.subTitle}
                        features={slide.features}
                        button={slide.button}
                        setDuration={slide.setDuration}
                    />
                ))}
            </div>
            <button className="carousel-button prev" onClick={() => moveSlide(-1)}>
                <img className="Icon-Large rotate90" src={Dropdown} alt="" />
            </button>
            <button className="carousel-button next" onClick={() => moveSlide(1)}>
                <img className="Icon-Large rotate270" src={Dropdown} alt="" />
            </button>
        </div>
    );
};

export default Carousel;
