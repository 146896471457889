import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext';
import CommentsLikes from "../../services/server/CommentsLikes";
import UserIcon from "../../assets/svg/UserIcon.svg"
import Images from "../../services/server/Images"

import CommentIcon from "../../assets/svg/Navigation/Comment-inactive.svg";
import LikeActive from "../../assets/svg/Navigation/Like-active.svg"
import LikeInactive from "../../assets/svg/Navigation/Like-inactive.svg"

import DateService from "../../helpers/DateService";
import toastMessage from '../../helpers/Toast';

function Comment(props) {

  let navigate = useNavigate();
  const {authState} = useContext(AuthContext);

  const [id, setId] = useState(null);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [numberOfLikes, setNumberOfLikes] = useState(0);
  const [image, setImage] = useState(null);

  useEffect(() => {

    async function fetchData() {
        let commentLikes = await CommentsLikes.getCommentLikesByCommentId(props.comment.id);
        if(commentLikes && commentLikes.length >= 1) {
          let userLike = commentLikes.find((value) => value.userId === authState.id && value.commentId === props.comment.id);
          if(userLike) {
            setLike(userLike.like);
            setDislike(userLike.dislike);
            setId(userLike.id);
          }
          setNumberOfLikes(commentLikes.filter((value) => value.like).length);
        } else {
          setId(null);
          setLike(false);
          setDislike(false);
          setNumberOfLikes(0);
        }
    }

    fetchData();

    if(props?.comment?.user?.image) {
      fetchImage(props.comment.user.image);
    }

  }, [props.comment])

    const fetchImage = async (image) => {
        let imageData = await Images.getUserImage(image);
        const imageBlob = new Blob([imageData])
        setImage(imageBlob);
    }

  const onLikeChange = async () => {
    
    let likeData = {
      like: !like,
      dislike: false,
      userId: authState.id,
      commentId: props.comment.id
    }
    if(id) { 
      await CommentsLikes.updateCommentLike({...likeData, id: id});
    } else {
      let response = await CommentsLikes.createCommentLike(likeData); 
      setId(response?.message.id);
    }
    setNumberOfLikes((numberOfLikes) => like ? Number(numberOfLikes)-1 : Number(numberOfLikes)+1)
    setDislike(false);
    setLike(!like);
  }

  const onDislikeChange = async () => {
    
    let dislikeData = {
      like: false,
      dislike: !dislike,
      userId: authState.id,
      commentId: props.comment.id,
    }

    if(id) {
      await CommentsLikes.updateCommentLike({...dislikeData, id: id})
    } else {
      let response = await CommentsLikes.createCommentLike(dislikeData); 
      setId(response?.message.id);
    }

    if(like)
      setNumberOfLikes((numberOfLikes) => Number(numberOfLikes)-1)
    setLike(false);
    setDislike(!dislike);
  }

  const onForeignLogin = () => {
    toastMessage("Create an Account to Like", "success");
    navigate("/entry");
  }
  
  return (
    <div className='CommentContainer'>
      <div className="UserImageContainer">
        <img className="UserImage" src={image ? URL.createObjectURL(image) : UserIcon} alt="User" />
      </div>
      <div className='Comment'>
        <div className='CommentStats'>
          <p>@{props.comment.user.username}</p>
          <br/>
          <p>{DateService.formatRelativeTime(props.comment.createdAt)}</p>
        </div>
        <div className='CommentText'>
          <h4 className='normal breakWord'>{props.comment.comment}</h4>
        </div>
        <div className='CommentFeedback'>
          <div className='LikesContainer'>
            <div className='LikeContainer'>
              <img className="Icon-Reduced Clickable" src={like ? LikeActive : LikeInactive} alt="Like" onClick={() => {authState.status ? onLikeChange() : onForeignLogin()}}/>
              <p>{numberOfLikes}</p>
            </div>
            <div className='DisLikeContainer'>
              <img className="Icon-Reduced UpsideDown Clickable" src={dislike ? LikeActive : LikeInactive} alt="Like" onClick={() => {authState.status ? onDislikeChange() : onForeignLogin()}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comment