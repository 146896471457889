import React from 'react';

import GmailLogo from "../../../assets/svg/GmailLogo.svg"

function Mailto({ email, subject = '', body = '', text }) {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
    
    return <a href={`mailto:${email}${params}`} 
            style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                gap: "2vh",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <h4 
                style={{flex: "0 0 auto"}}
            className='noMargin normal black'>{text}</h4>
            <button 
                className='button-secondary' 
                style={{
                    display: "flex",
                    flex: "0 0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2vw"
                }}
            >
                <img src={GmailLogo} className='Icon' alt="Email Icon"></img>
                <h4 className='noMargin normal'>{email}</h4>
            </button>
        </a>;

}

export default Mailto;
