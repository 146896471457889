import React, {useState, useEffect} from 'react'

import Comments from "../../services/server/Comments"

import CommentInput from './CommentInput'

import Comment from "./Comment"

function CommentsSection(props) {

  const [comments, setComments] = useState([]);

  useEffect(() => { 
    fetchData();
  }, [])
  
  async function fetchData() {
    let commentsResponse = await Comments.getCommentsByProjectId(props.projectId);
    if(commentsResponse) {
      setComments(commentsResponse);
      props.setItemsLength(commentsResponse.length)
    }
  }

  return (
    <div className='CommentsSection'>
        <div className='CommentsList'>
            {
                comments.map((value, key) => {
                    return <Comment key={key} comment={value}/>
                })
            }
        </div>
        <CommentInput projectId={props.projectId} commentEntered={fetchData}/>
    </div>
  )
}

export default CommentsSection