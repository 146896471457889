import React, {useState} from 'react'
import axios from 'axios'

import Validation from '../../../helpers/Validation';
import toastMessage from '../../../helpers/Toast';
import Input from '../Input';

function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [status, setStatus] = useState(false);

    async function onSubmit(e) {

        e.preventDefault();

        if(Validation.email(email)) {
            let data = {email: email};
            setStatus(true);
    
            await axios.post(process.env.REACT_APP_SERVER_URL + '/users/forgotPassword', data).then((resp) => {
                if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                } 
            })
        }
    }

    return (
        <>
        { !status ?
            <form className='Container justForm' onSubmit={(e) => onSubmit(e)}>
                <Input 
                    tabIndex="1"
                    value={email}
                    setValue={setEmail}
                    placeholder={"Enter Your Email"}
                    label={"Email"}
                />
                <button className="button-secondary" onClick={(e) => onSubmit(e)}><h3>Send Email</h3></button>
            </form> 
            :
            <div className='Container Notice'>
                <span className='ModalParagraph'>If the email exists, an email will be sent to {email} in order to reset your password.</span>
                <span className='ModalParagraph'>This only works if you have an existing Wanderbuilds account.</span>
            </div> 
        }
        </>
    )
}

export default ForgotPassword