import React, {useState, useRef, useEffect, useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../helpers/AuthContext';

import Followers from "../../services/server/Followers"

import Input from './Input';
import CopyInactive from '../../assets/svg/Copy-inactive.svg'
import CopyActive from '../../assets/svg/Copy-active.svg'
import UserIcon from "../../assets/svg/UserIcon.svg"

import DateService from "../../helpers/DateService";

import Images from "../../services/server/Images"
import ProjectFavorites from '../../services/server/ProjectFavorites';

import toastMessage from "../../helpers/Toast";
import Share from "../../services/Share";

function ProjectInfoBoard(props) {

  const {authState} = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [favoritesCount, setFavoritesCount] = useState(null);
  const [followCount, setFollowCount] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [follow, setFollow] = useState(false);
  const [copy, setCopy] = useState(CopyInactive);
  const [image, setImage] = useState(null);
  const linkRef = useRef(process.env.REACT_APP_WEBSITE_URL + location.pathname);

  useEffect(() => {
      fetchFavoritesCountData();
  }, [])

  useEffect(() => {
    if(props.developer && props.developer.id) {
      fetchNumberOfFollowersData();
      if(authState.status) {
        fetchUserFollowStatus();
      }
      if(props.developer.image) {
        fetchImage(props.developer.image);
      }
    } 
  }, [props.developer])

  useEffect(() => {
    if(props.createdAt) {
      setCreatedDate(DateService.formatDate(props.createdAt))
    }
  } , [props.createdAt])

  const fetchImage = async (image) => {
    let imageData = await Images.getUserImage(image);
    const imageBlob = new Blob([imageData])
    setImage(imageBlob);
  }

  async function fetchFavoritesCountData() {
    let response = await ProjectFavorites.getFavoritesCountByProjectId(props.projectId);
    setFavoritesCount(response.count);
  }

  async function fetchNumberOfFollowersData() {
    let response = await Followers.getFollowersByUserId(props.developer.id);
    setFollowCount(response.count);
  }

  async function fetchUserFollowStatus() {
    let response = await Followers.getUserFollowStatus(props.developer.id);
    if(response.id) {
      setFollow({status: response.status, id: response.id});
    }
  }

  const onFollow = async () => {

    let data = {
      status: !follow.status,
      followerId: authState.id,
      userId: props?.developer?.id,
    }

    if(follow.id) {
      await Followers.updateFollower({...data, id: follow.id});
      setFollow({...follow, status: !follow.status})
    } else {
      let response = await Followers.createFollower(data);
      setFollow({status: response.message.status, id: response.message.id});
    }
    fetchNumberOfFollowersData();
  }

  const onForeignInput = () => {
    toastMessage("Create an Account to Follow a User", "success");
    navigate("/entry");
  }

  return (
    <div className='InfoBoardContainer'>
        <div className='GameDataDashBoard'>
          <div className='DetailsDisplay'>
            <h3>{favoritesCount}</h3>
            <h5 className='normal grey noMargin'>Favorites</h5>
          </div>
          <div className='DetailsDisplay'>
            <h3>{props.plays}</h3>
            <h5 className='normal grey noMargin'>Plays</h5>
          </div>
          <div className='DetailsDisplay'>
            { createdDate &&
            <>
              <h3>{createdDate[0] + " " + createdDate[1]}</h3>
              <h5 className='normal grey noMargin'>{createdDate[2]}</h5>
            </>
            }
          </div>
        </div>
        <div className='GameOptionsContainer'>
          <div className='optionDisplay Clickable'>
            <h4 className='normal grey noMargin'>Share Game</h4>
            <div className='copyRow'>
              <Input value={linkRef.current} disabled/>
              <img 
                className="Icon IconBorder" 
                src={copy} alt="Copy Link"
                onClick={() => Share.shareLink(props.title, props.thumbnail, linkRef.current)}
                onMouseOver={() => setCopy(CopyActive)} 
                onMouseOut={() => setCopy(CopyInactive)}
                onTouchStart={() => setCopy(CopyActive)} 
                onTouchEnd={() => setCopy(CopyInactive)}
              />
            </div>
            {/* <img className="Icon" src={Share} alt="ShareIcon" /> */}
          </div>
        </div>
      <div className='UserSubscribeContainer'>
        <div className='UserImageContainer'>
          <img className="UserImage-Large" src={image ? URL.createObjectURL(image) : UserIcon} alt="User Image" />
          {props.developer && <h4 className='centerText'>@{props?.developer?.username}</h4>}
        </div>
        {
          props?.developer?.id === authState.id ?
          <div className='UserDetailsContainer'>
            <h4 className='centerText noMargin'>{followCount} followers</h4>
          </div> 
          :
          <div className='UserDetailsContainer'>
            <button className={follow.status ? 'button-inverse button-border' : 'button-secondary'} onClick={() => authState.status ? onFollow() : onForeignInput()}><h4>{follow.status ? "Following" : "Follow"}</h4></button>
            <h5 className='centerText noMargin'>{followCount} followers</h5>
          </div>
        }
      </div>
    </div>
  )
}

export default ProjectInfoBoard