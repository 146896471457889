import React, { useState, useEffect } from 'react'

import Loading from "./atoms/Loading";
import PreventRefresh from './atoms/PreventRefresh';

function Preview(props) {

  const [srcDoc, setSrcDoc] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if(props.scripts) {
      setSrcDoc(`
        <html>
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Bouncing Ball</title>
            <script src="https://cdn.jsdelivr.net/npm/p5@1.9.1/lib/p5.js"></script>          
            <style>
              html, body {
                margin: 0;
                height: 100%;
                overflow: auto; /* Allow scrolling */
              }
            </style>
          </head>
          <body>
            <script>
              document.addEventListener('touchstart', (event) => {
                event.preventDefault();
              });

              document.addEventListener('touchmove', (event) => {
                event.preventDefault();
              }, { passive: false }); // Ensure we can prevent default
            </script>
            ${
              props.scripts.map((value, key) => {
                return `<script key=${key}>${value.content}</script>`
              }).join('')
            }
          </body>
          <style>body{margin: 0; height: 100%; overflow: hidden}</style>
        </html>`)
    }

  }, [props.scripts])

  const handleLoad = (e) => {
    setIsLoaded(true);
  };

  return (
      <div className="Preview" id="Preview">
        {!isLoaded && 
        <Loading />
        }
        <PreventRefresh >
          <iframe
            key={props.refresh}
            title="Preview"
            sandbox='allow-scripts'
            frameBorder="0"
            width="100%"
            height="100%"
            srcDoc={srcDoc}
            onLoad={(e) => handleLoad(e)}
            loading='lazy'
          />

        </PreventRefresh>
      </div> 
  )
}

export default Preview