import React, {useContext, useState} from 'react'
import { AuthContext } from '../../../helpers/AuthContext';

import Subscription from '../../../services/server/Subscription';
import DateService from '../../../helpers/DateService';

import PricingDashboard from '../PricingDashboard';
import Modal from "../entry/Modal";
import TwoOptionForm from './TwoOptionForm';

function SubscriptionMenu() {

  let {authState, setAuthState} = useContext(AuthContext);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);

    const onCancelSubscription = async () => {
        let response = await Subscription.cancelSubscription();
        if(response) {
        setAuthState({...authState, subscription: response.subscription})
        }
    }

    return (
    <div className='UserSettings'>
      <div className='SettingsTitle'>
        <h4>Subscription</h4>
      </div>
      <div className='SettingsRow'>
        <div className='SubscriptionInfo'>
          {
            authState.subscription.status === "complete" ?
            <>
              <h4 className='noMargin normal'>Current Subscription: Builder</h4>
              <h4 className='noMargin normal'>Renewal Date: {DateService.formatNiceDate(authState.subscription.renewalDate)}</h4>
              <h5 className='noMargin normal primary Clickable' onClick={() => setCancelSubscriptionModal(true)}>Cancel Subscription</h5>
              <Modal 
                  title={"Cancel Subscription"}
                  show={cancelSubscriptionModal}
                  setShow={() => {setCancelSubscriptionModal(false)}}
                  content={<TwoOptionForm 
                              title={`Are you sure you want to cancel your Builder, Subscription?`}
                              subtitle="Doing so will set all your current projects to private."
                              option1="Go Back"
                              option2="Yes, Cancel Subscription"
                              onOptionOne={() => setCancelSubscriptionModal(false)}
                              onOptionTwo={onCancelSubscription}
                          />}
              />
            </>
            :
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "2vh"
            }}>
              <h4 className='noMargin normal'>Current Subscription: Free</h4>
              <PricingDashboard text={"Check out Builder Subscription"}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SubscriptionMenu