import React, {useState} from 'react';

import Input from "./Input";

function NewFile(props) {

    const [fileName, setFileName] = useState("");

    const onSubmit = (e) => {

        e.preventDefault();

        props.setFileName(fileName);

    }

    return (
        <div className='Container Form justForm'>
            <div className='fields'>
                <Input tabIndex="1" value={fileName} setValue={setFileName} placeholder={"Enter Filename"} />
            </div>
            <div className='submission'>
                <button tabIndex="2" onClick={(e) => onSubmit(e)}>
                    <h4 className='noMargin'>Create File</h4>
                </button>
            </div>
        </div>
    )
}

export default NewFile