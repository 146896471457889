import React from 'react'
import { useNavigate } from 'react-router-dom'

function CheckboxInput(props) {
    const navigate = useNavigate();
  return (
    <div
        style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            width: "100%",
            gap: "2vh",
            textAlign: "center",
            verticalAlign: "center"
        }}
        >
        <input
            type="checkbox"
            checked={props.checkbox}
            style={{
                display: "flex",
                border: "none",
                accentColor: "#0C58B0",
                width: "fit-content",
                cursor: "pointer",
                transform: "scale(1.5)",
                margin: "3px 0 0 2vw",
            }}
            onChange={(e) => props.setCheckbox(e.target.checked)}
            required
        />
        <p className='noMargin breakWord'>
            I accept the <strong 
            style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer"
            }}
            onClick={(e) => {
                e.preventDefault();
                window.onbeforeunload = null;
                navigate(props.link, {
                    state: {
                        prevUrl: props.prevUrl,
                        goBack: {
                            username: props.username,
                            password: props.password,
                            passwordTwo: props.passwordTwo,
                            termsPolicyAccepted: props.termsPolicyAccepted,
                            privacyPolicyAccepted: props.privacyPolicyAccepted,
                        }
                    }
                });
            }}>{props.title}</strong>
        </p>
    </div>
  )
}

export default CheckboxInput