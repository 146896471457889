import React from 'react'

function NoResults(props) {
  return (
    <div className='NoResults'>
        <h3 className='normal noMargin tertiaryFont'>{props.message}</h3>
    </div>
  )
}

export default NoResults