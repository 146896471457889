import React, {useContext} from 'react'
import { AuthContext } from '../../../helpers/AuthContext';

import Icon from './Icon'
import Mailto from './Mailto'

import YoutubeLogo from "../../../assets/svg/YoutubeLogo.svg"
import XLogo from "../../../assets/svg/XLogo.svg"
import FacebookLogo from "../../../assets/svg/FacebookLogo.svg"
import InstagramLogo from "../../../assets/svg/InstagramLogo.svg"
import LinkedinLogo from "../../../assets/svg/LinkedinLogo.svg"
import TiktokLogo from "../../../assets/svg/TiktokLogo.svg"

function SocialMenu() {
  let {authState} = useContext(AuthContext);


  return (
    <div className='UserSettings'>
        <div className='SettingsTitle'>
            <h4>Contact us</h4>
        </div>
        <div className='SettingsRow'>

            {
                authState.subscription.status === "complete" ?
                <Mailto 
                    email={"support@wanderbuilds.com"}
                    subject={"Support"}
                    body={"Hey, can you give me a hand with..."}
                    text={"For any queries contact us at"}
                />
                :
                <Mailto 
                    email={"info@wanderbuilds.com"}
                    subject={"Information"}
                    body={"Hey, i am curious about..."}
                    text={"For more information contact us at"}
                />
            }
        </div>
        <div className='SettingsRow' style={{flexWrap: "wrap"}}>
            <Icon
                title="Youtube"
                src="https://youtube.com/@wanderbuilds"
                icon={YoutubeLogo}    
            />
            <Icon
                title="XLogo"
                src="https://x.com/wanderbuilder"
                icon={XLogo}    
            />
            <Icon
                title="Tiktok"
                src="https://www.tiktok.com/@wanderbuilder"
                icon={TiktokLogo}    
            />
            <Icon
                title="Facebook"
                src="https://www.facebook.com/share/4dXVJEbK2Eqmjg6w"
                icon={FacebookLogo}    
            />
            <Icon
                title="Instagram"
                src="https://www.instagram.com/wanderbuilder"
                icon={InstagramLogo}    
            />
            <Icon
                title="Linkedin"
                src="https://www.linkedin.com/company/wanderbuilds/"
                icon={LinkedinLogo}    
            />
        </div>
    </div>
  )
}

export default SocialMenu