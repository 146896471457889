import React from 'react'

function Icon({title, src, icon}) {
  return (
    <a 
        href={src}
        style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row"
        }}
        target="_blank" 
        rel="noopener noreferrer"
    >
        <img className='Icon-Large' src={icon} alt={`${title} Logo`}/>
    </a>
  )
}

export default Icon