import toastMessage from "../helpers/Toast";
import Images from "./server/Images"

export default class Share {
    
    static async shareLink(title, image, linkRef) {

        const imageBlob = new Blob([await Images.getUserImage(image)]);

        const shareData = {
            title: title,
            text: 'Try out this WanderBuilds Game!',
            url: linkRef,
            // Optional: Add an image URL if you want to share an image
            files: [new File([imageBlob], 'image.png', { type: imageBlob.type })]// Uncomment and replace with your image file
        };

        try {
            if (navigator.share) {
                await navigator.share(shareData);
            } else {
                await this.copyLinkToClipboard(linkRef);
            }
        } catch (err) {
            console.error('Error sharing:', err);
            await this.copyLinkToClipboard(linkRef); // Fallback to copy if sharing fails
        }
    };

    static async copyLinkToClipboard(linkRef) {
        try {
            await navigator.clipboard.writeText(linkRef);
            toastMessage("Copied Link to Clipboard", "success");
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
}

