import axios from "axios";

import toastMessage from "../../helpers/Toast";

import Cache from "../Cache";

export default class Analytics {

    static async getUsersProjectsAnalytics() {

        let cacheStatus = Cache.checkCached("userAnalytics");
        if(cacheStatus) 
            return cacheStatus;

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/projects/analytics/user", 
            { 
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }

        Cache.setCache("userAnalytics", response.data);
    
        return response.data;        
    }

    static async getProjectAnalytics(id) {

        let cacheStatus = Cache.checkCached("userAnalytics" + id);
        if(cacheStatus) 
            return cacheStatus;

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/projects/analytics/project/" + id, 
            { 
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }

        Cache.setCache("userAnalytics" + id, response.data);
    
        return response.data;        
    }
}