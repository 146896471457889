import React, {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom";

import Input from "../Input";

import Search from "../../../assets/svg/Search.svg";
import BackArrow from "../../../assets/svg/Navigation/BackArrow.svg"

function SearchField(props) {

    let navigate = useNavigate();
    const [searching, setSearching] = useState(false);
    const [value, setValue] = useState("");
    const [list, setList] = useState([]);


    useEffect(() => {
        if(props.list) {
            setList(props.list);
        }
    }, [props])

    const onSearchChange = (searchValue) => {
        setValue(searchValue);

        setList(props.list.filter((value) => value.name.toLowerCase().includes(searchValue.toLowerCase())));
    }

    if(searching)
        return (
            <div className='SearchContainer'>
                <div className='SearchInput'>
                    <img className="Icon" src={BackArrow} alt="Go Back" onClick={() => setSearching(false)}/>
                    <Input value={value} setValue={onSearchChange} placeholder={"Search WanderBuilds"}/>
                </div>
                <div className='SearchOptionsList'>
                    {
                        list.map((value, key) => {
                            return (
                                <div className='SearchOption' key={key} onClick={() => navigate('/game/' + value.entityId)}>
                                    <h4 className='noMargin normal breakWord'>{value.name}</h4>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )

    return (
        <img className="Icon" src={Search} alt="Search" onClick={() => setSearching(true)}/>
    )
}

export default SearchField