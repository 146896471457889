import { toast } from 'react-toastify';

var position = toast.POSITION.TOP_RIGHT;

export default function toastMessage(message, type) {

    switch(type) {
        case "success": 
            toast.success(message, {
                position: position
            });
        break;
        case "error": 
            toast.error(message, {
                    position: position
                });
            break;
        case "warning": 
            toast.warning(message, {
                position: position
            });
            break;
        default:
            break;
    }
}