import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Comments {

    static async getCommentsByProjectId(projectId) {

        try {
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/comments/project/" + projectId,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                },  
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;       
        } catch(e) {
            toastMessage(e.response.data.error, "error");
        }

    }

    static async createComment(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/comments", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}
