
import DateService from "./DateService";

export default class Analytics {

    static getGraphData(rows, timeFrame, type, start) {

        
        const currentDate = new Date();
        let startDate = new Date();

        let initialDate = null;

        switch (timeFrame)  {
            case "28days":
                initialDate = currentDate.getDate() - 28;
                startDate.setDate(initialDate);
                break;
            case "6months":
                initialDate = DateService.get6MonthsAgo();
                startDate = initialDate;
                break;
            case "year":
                initialDate = DateService.getAYearAgo();
                startDate = initialDate;
                break;
            case "alltime":
                initialDate = new Date(start)
                startDate = initialDate;
                break;
            default:
                initialDate = new Date(start)
                startDate = initialDate;
                break;       
            }
            
        const labels = [];
        const views = [];
        
        let date = startDate;
        while (date <= currentDate) {
            labels.push(new Date(date));
            let onDay = rows.filter((value) => Analytics.isSameDay(new Date(value.updatedAt), new Date(date)));
            if(type === "time") {
                views.push(DateService.timePlayedInHours(onDay));
            } else {
                views.push(onDay.length);
            }
            date.setDate(date.getDate() + 1);
        }

        return { labels: labels, data: views};
    }

    static isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    }
}