import React, {useState} from 'react'

import OpenIcon from "../../assets/svg/Open.svg";
import DeleteIcon from "../../assets/svg/Delete.svg"
import Javascript from "../../assets/svg/Javascript.svg"

import Modal from "./entry/Modal";
import TwoOptionForm from './atoms/TwoOptionForm';

function FileLine(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteFile = () => {
        setShowDeleteModal(false);
        props.onDeleteFile();
    }

    return (
        <div className='FileLine'>
            <div className='Identification'>
                <img className='Icon' src={Javascript} alt="Javascript"/>
                <h4>{props.name}.js</h4>
            </div>
            <div className='Options'>
                <img 
                    src={DeleteIcon} 
                    style={{
                        color:"red",
                        fill: 'red',
                        stroke: 'red'
                    }} 
                    alt="Delete File" 
                    className='Icon-Normal' onClick={() => setShowDeleteModal(true)}
                />
                <img src={OpenIcon} alt="Open File" className='Icon-Normal' onClick={props.openFile}/>
            </div>
            <Modal 
                title={"Delete File"}
                show={showDeleteModal}
                setShow={() => {setShowDeleteModal(false)}}
                content={<TwoOptionForm 
                            title={`Are you sure you want to delete the file ${props.name}.js`}
                            subtitle="This action is irreversible."
                            option1="cancel"
                            option2="Yes, Delete"
                            onOptionOne={() => setShowDeleteModal(false)}
                            onOptionTwo={() => onDeleteFile()}
                        />}
            />
        </div>
    )
}

export default FileLine