import React, {useState, useEffect, useContext} from 'react'

import Analytics from "../../services/server/Analytics";
import AnalyticsService from '../../services/AnalyticsService';

import { AuthContext } from '../../helpers/AuthContext';

import Graph from "./atoms/Graph"
import FilterTags from "./FilterTags";
import Loading from './atoms/Loading';

function AnalyticsBoard(props) {

  const {authState} = useContext(AuthContext);
  const [analytics, setAnalytics] = useState(null);
  const [selected, setSelected] = useState(0);
  const [graphData, setGraphData] = useState(null);
  const [graphInfo, setGraphInfo] = useState({title: "Plays", number: 0});
  const [timeFrame, setTimeFrame] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    async function fetchData() {
      let data = await Analytics.getUsersProjectsAnalytics();
      setAnalytics(data);
      setGraphInfo({...graphInfo, number: data.plays})
      setIsLoading(false);
    }

    async function fetchProjectData() {
      let data = await Analytics.getProjectAnalytics(props.project.id);
      setAnalytics(data);
      setGraphInfo({...graphInfo, number: data.plays})
      setIsLoading(false);
    }
    
    if(props.project) {
      fetchProjectData();
    } else {
      fetchData();
    }
  }, [])

  useEffect(() => {
    if(analytics)
      setTimeFrame("28days");
  }, [analytics])


  useEffect(() => {
    if(timeFrame) {
      let allTimeDate = props.project ? props.project.createdAt : authState.createdAt;
      switch(selected) {
          case 0: 
            setGraphData(AnalyticsService.getGraphData(analytics?.playsData, timeFrame, "count", allTimeDate));
            break;
          case 1:
            setGraphData(AnalyticsService.getGraphData(analytics?.followersData, timeFrame, "count", allTimeDate));
            break;
          case 2: 
            setGraphData(AnalyticsService.getGraphData(analytics?.favoritesData, timeFrame, "count", allTimeDate));
            break;
          case 3: 
            setGraphData(AnalyticsService.getGraphData(analytics?.playsData, timeFrame, "time", allTimeDate));
            break;
          default:
            break;
        }
    }
  }, [timeFrame, selected])

  const onGraphChange = (index) => {
    setSelected(index);

    switch(index) {
      case 0: 
        setGraphInfo({title: "Plays", number: analytics?.plays});
        break;
      case 1: 
        setGraphInfo({title: "Followers", number: analytics?.numberOfFollowers});
        break;
      case 2: 
        setGraphInfo({title: "Favorites", number: analytics?.numberOfFavorites});
        break;
      case 3: 
        setGraphInfo({title: "Time Played (hours)", number: analytics?.timePlayed});
        break;
      default: 
        setGraphInfo({title: "Plays", number: analytics?.plays});
        break;

    }
  }

  if(isLoading)
    return (
      <Loading />
    )

  return (
    <div className="AnalyticsBoardContainer">
      <FilterTags 
        tags={[
          {
            options: [
              {
                label: "Last 28 days",
                value: "28days"
              },
              {
                label: "Last 6 months",
                value: "6months"
              },
              {
                label: "Last Year",
                value: "year"
              },
              {
                label: "All Time",
                value: "alltime"
              },
            ]
          },
        ]}
        onClick={(value, key) => {
          setTimeFrame(value);
        }}
      />
      <div className='AnalyticsContainer'>        
        <div className='BoardContainer'>
          <div className='BoardRow'>
            <div 
              className={selected === 0 ? 'AnalyticsItemSelected' : 'AnalyticsItem'}
              onClick={() => {onGraphChange(0)}}
            >
              <h5 className='noMargin normal breakWord'>Plays</h5>
              <h4>{analytics?.plays}</h4>
            </div>
            
            { analytics?.numberOfFollowers !== undefined ?
              <div 
              className={selected === 1 ? 'AnalyticsItemSelected' : 'AnalyticsItem'}
              onClick={() => {onGraphChange(1)}}
            >
              <h5 className='noMargin normal breakWord'>Followers</h5>
              <h4>{analytics?.numberOfFollowers}</h4>
            </div> : null
            }
          </div>
          <div className='BoardRow'>
            <div 
              className={selected === 2 ? 'AnalyticsItemSelected' : 'AnalyticsItem'}
              onClick={() => {onGraphChange(2)}}
            >
              <h5 className='noMargin normal breakWord'>Favorites</h5>
              <h4>{analytics?.numberOfFavorites}</h4>
            </div>
            <div 
              className={selected === 3 ? 'AnalyticsItemSelected' : 'AnalyticsItem'}
              onClick={() => {onGraphChange(3);}}
            >
              <h5 className='noMargin normal breakWord'>Time Played (hours)</h5>
              <h4>{analytics?.timePlayed}</h4>
            </div>
          </div>
        </div>
        <div className='GraphsContainer'>
          {graphData && graphInfo && <Graph title={graphInfo.title} number={graphInfo?.number} data={graphData}/>}
        </div>
      </div>
    </div>
  )
}

export default AnalyticsBoard