import React, {useState, useEffect} from 'react'

import Images from "../../../services/server/Images"

import ImageIcon from "../../../assets/svg/ImageIcon.svg";
import {maxSize} from "../../../helpers/Constants";
import toastMessage from '../../../helpers/Toast';

function ImageInput(props) {

    const [image, setImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    useEffect(() => {

        async function fetchImage() {
            setImageLoading(true);
            let imageData = await Images.getUserImage(props.userImage);
            const imageBlob = new Blob([imageData]);
            setImage(imageBlob);
            setImageLoading(false);
        }

        if(props.userImage) {
            fetchImage();
        }

    }, [props.userImage])

  return (
    <div className='ImageInput'>
        {
            props.disabled ?
            (
                <div className='ImageUploadContainer centerText'>
                    <p className='normal noMargin centerText'>Saving...</p>
                </div>
            )
            :
            (
                <>
                <label for="imageUpload" className="ImageUploadContainer">
                    <div className='imageUploadBlock'>
                        <img id="svg-icon"className="Icon imageUpload" src={ImageIcon} alt="Image Icon" />
                    </div>
                    {
                        imageLoading
                        ? 
                        (
                            <p className='centerText UserImageInside wordBreak'>Loading Image...</p>
                        ) :
                        (
                            image ?
                            <img 
                                className='UserImage-Massive UserImageInside' 
                                src={URL.createObjectURL(image)} 
                                alt="Users Image" 
                            />
                            :
                            null
                            // <p className='centerText'>No Image</p>
                        )
                    }
                </label>
                <input 
                    type="file" 
                    id="imageUpload" 
                    name={props.name}
                    accept="image/png, image/jpeg" 
                    onChange={
                        (e) => {
                            if(e.target.files[0].size > maxSize) {
                                toastMessage("Image Size Exceeds 5MB limit", "error");
                            } else {
                                props.onChange(e.target.files[0])
                            }
                        }
                    } 
                    disabled={props.disabled}
                    data-max-size="5242880"
                />
                </>
            )
        }
    </div>
  )
}

export default ImageInput