import React, {useEffect, useState} from 'react'

function Modal(props) {

    const {title, content} = props;

    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show);
    }, [props.show])

    const exit = () => {
        setShow(false);
        props.setShow(false);
    }

    if(!show) 
        return null;
    
    return (
        <div className='Modal'>
            <div className='Content'>
                <div className='Heading'>
                    <h3 className='title'>{title}</h3>
                    <button onClick={exit} className='exit'>X</button>
                </div>
                <div className='action central'>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Modal