import React, {useState, useRef, useContext, useEffect} from 'react'
import { useNavigate } from "react-router-dom"
import toastMessage from '../../helpers/Toast';

import { AuthContext } from '../../helpers/AuthContext';
import Comments from "../../services/server/Comments"
import Images from "../../services/server/Images";

import Input from "./Input";

import UserIcon from "../../assets/svg/UserIcon.svg";
import Validation from '../../helpers/Validation';

function CommentInput(props) {

    const {authState} = useContext(AuthContext);
    let navigate = useNavigate();

    const [comment, setComment] = useState("");
    const [image, setImage] = useState();
    const [canComment, setCanComment] = useState(true);
    const commentRef = useRef(comment);

    useEffect(() => {
        if(authState.status && authState.image) {
            fetchImage(authState.image);
        }
    }, [authState])


    const fetchImage = async (image) => {
        let imageData = await Images.getUserImage(image);
        const imageBlob = new Blob([imageData])
        setImage(imageBlob);
    }

    const onEnter = async () => {
        if(authState.status) {
            if(canComment) {
                setCanComment(false);

                if(Validation.isObscene(commentRef.current)) {
                    toastMessage("Profanity is not allowed on WanderBuilds!", "error")
                    setCanComment(true);
                    return;
                }

                let data = {
                    comment: commentRef.current,
                    projectId: props.projectId,
                    userId: authState.id
                }
                
                let response = await Comments.createComment(data);
        
                props.commentEntered(response);
                setComment("");
                setCanComment(true);
            }
        } else {
            toastMessage("Create an Account to Comment", "success");
            navigate("/entry");
        }
    }

    const onCommentChange = (v) => {
        setComment(v);
        commentRef.current = v;
    }

  return (
    <div className='CommentInputContainer'>
        <div className='UserImageContainer'>
            <img className="UserImage" src={image ? URL.createObjectURL(image) : UserIcon} alt="User" />
        </div>
        <div className='CommentInput'>
            <Input 
                tabIndex={0} 
                value={comment} 
                setValue={onCommentChange} 
                placeholder={"Add your comment..."} 
                background={"#f1f1f1"} 
                onEnter={onEnter}
                disabled={!canComment}
            />
        </div>
    </div>
  )
}

export default CommentInput