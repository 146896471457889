import React, {useState, useEffect} from 'react'

import Dropdown from "../../assets/svg/Dropdown.svg"

function MenuItem(props) {

  useEffect(() => {
    props.active ? setActive(true) : setActive(false);
  }, [props.active])

  const [active, setActive] = useState(false);

  return (
    <div className='Item' onClick={() => props.onClick()}>
        <div className='start'>
            <img src={props.icon} alt={props.title} className='Icon-Massive'/>
            <h3 className='normal centerText'>{props.title}</h3>
        </div>
        {
            props.dropdown &&
            <div className='end'>
                <img src={Dropdown} alt="Dropdown" className={active ? 'Icon' : 'Icon UpsideDown'} />
            </div>
        }
    </div>
  )
}

export default MenuItem