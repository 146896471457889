import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Files {

    static async getFiles(projectEntityId) {

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/files/" + projectEntityId,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async createFile(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/files", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async updateFile(body) {

        let response = await axios.patch(
            process.env.REACT_APP_SERVER_URL + "/files", 
            body,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async deleteFile(id) {

        try {
            await axios.delete(
                process.env.REACT_APP_SERVER_URL + "/files/" + id,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                },
            )

        } catch(e) {
            toastMessage("Error Deleting File", "error");
        }
    }
}
