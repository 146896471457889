
export default class DateService {

    static timePlayedInHours(playsRows) {
        // figure out time watched
        let timePlayed = 0;
        playsRows.map((value) => timePlayed += Date.parse(value.endDate) - Date.parse(value.startDate));
        timePlayed = (timePlayed/3600000).toFixed(2);
        return timePlayed;
    }
    static get6MonthsAgo() {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth();

        if (currentMonth === 0) {
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            currentDate.setMonth(6);
        } else {
            currentDate.setMonth(currentMonth - 6);
        }

        let daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        if (currentDate.getDate() > daysInMonth) {
            currentDate.setDate(daysInMonth);
        }

        return currentDate;
    }
    static getAYearAgo() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        currentDate.setFullYear(currentYear - 1);

        return currentDate;
    }
}