import React, {useContext, useState} from 'react'
import { AuthContext } from '../../../helpers/AuthContext';
import { useNavigate } from 'react-router-dom';
import Cache from '../../../services/Cache';

import Modal from "../entry/Modal";
import TwoOptionForm from './TwoOptionForm';

function UserSettings(props) {

  let navigate = useNavigate();
  let {setAuthState} = useContext(AuthContext);
  const [logOutModal, setLogOutModal] = useState(false);

  const logout = () => {
    localStorage.removeItem('accessToken');
    setAuthState(null)
    Cache.deleteAllCache();
    navigate("/entry");
  }

  return (
    <div className='UserSettings'>
      <div className='SettingsTitle'>
        <h4>Settings</h4>
      </div>
        <div className='SettingsRow Clickable' 
        onClick={(e) => {
          e.preventDefault();
          window.onbeforeunload = null;
          navigate("/terms-of-service", {
            state: {
              prevUrl: "/home",
              goBack: {
                item: 4,
              }
            }
          });
        }}>

            <h4 className='noMargin normal'>Terms of Service</h4>
        </div>
        <div 
          className='SettingsRow Clickable'
          onClick={(e) => {
            e.preventDefault();
            window.onbeforeunload = null;
            navigate("/privacy-policy", {
              state: {
                prevUrl: "/home",
                goBack: {
                  item: 4,
                }
              }
            });
          }}
        >
          <h4 className='noMargin normal'>Privacy Policy</h4>
        </div>
      <div className='SettingsRow Clickable' onClick={() => setLogOutModal(true)}>
        <h4 className='noMargin normal primary'>Logout</h4>
      </div>
          <Modal 
            title={"Log Out"}
            show={logOutModal}
            setShow={() => {setLogOutModal(false)}}
            content={
              <TwoOptionForm 
                title={`Are you sure you want to Logout of WanderBuilds?`}
                option1="Go Back"
                option2="Yes, Logout"
                onOptionOne={() => setLogOutModal(false)}
                onOptionTwo={logout}
              />
            }
          />
    </div>
  )
}

export default UserSettings