import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext';

import Subscription from '../../services/server/Subscription';

import LandingPageBackground from "./entry/LandingPage/LandingPageBackground"
import PricingContainer from './atoms/PricingContainer';
import PremiumActive from "../../assets/svg/Navigation/Premium-active.svg";
import LogoAndMessage from "../../assets/svg/LogoAndMessage.svg";
import X from "../../assets/svg/X.svg";
import toastMessage from '../../helpers/Toast';
import Carousel from './atoms/Carousel';

function PricingDashboard(props) {

    let navigate = useNavigate();
    let {authState, setAuthState} = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [duration, setDuration] = useState("month");

    useEffect(() => {
        setShow(props.show);
    }, [props.show])

    const onSelected = async (type) => {

        if(authState.subscription.name === type) {
            toastMessage(`You already have a ${type} subscription`, "error");
            return;
        }

        if(type !== "gamer" && type !== "builder") {
            toastMessage(`Incorrect Subscription ${type}`, "error");
            return;
        }

        await Subscription.createCheckoutSession({
            duration: duration
        });
    }

    const onExit = () => {
        if(props.onExit) {
            props.onExit();
        } else {
            setShow(false);
        }
    }

    if(show)
        return <div className='PricingDashboardContainer' style={{overflow: "hidden"}}>
            <div className='HeaderContainer'>
                <div className='Header'>
                    <div className='start'>
                        <img src={LogoAndMessage} alt="Logo" />
                    </div>
                    <div className='end'>
                        <img className="Icon-Reduced" src={X} alt="Exit" onClick={onExit}/>
                    </div>
                </div>
            </div>
            <div className='PricingListContainer'>

                <Carousel
                    slides={[
                        {
                            id: 0,
                            title: "Taster",
                            value: "free",
                            subTitle: "Try out Building!",
                            features: [
                                {
                                    value: "1 Private Project"
                                },
                                {
                                    value: "No Public Projects"
                                },
                                {
                                    value: "No Unlisted Project"
                                },
                                {
                                    value: "1MB of Storage"
                                }
                            ],
                            button: {
                                text: "Continue",
                                style: (!authState.subscription.status || !authState.subscription.name === "builder") ? 
                                    {
                                        backgroundColor: "#0C58B080",
                                    } : 
                                    {
                                        display: "none"
                                    }
                                ,
                                onClick: () => {onExit()}
                            },
                        },
                        {
                            id: 1,
                            title:"Builder",
                            value:"builder",
                            setDuration: (value) => setDuration(value),
                            subTitle:"Share all your Projects",
                            features:[
                                {
                                    value: "Private Projects"
                                },
                                {
                                    value: "Public Projects"
                                },
                                {
                                    value: "Unlisted Projects"
                                },
                                {
                                    value: "5GB of Storage"
                                }
                            ],
                            button:{
                                text: authState.subscription.name === "builder" ? "You are a Builder" : "Become a Builder",
                                textStyle: authState.subscription.status && authState.subscription.name === "builder" ?
                                    {
                                        color: "#0C58B0",
                                    }
                                    :
                                    {
                                        
                                    },
                                style:
                                    authState.subscription.status && authState.subscription.name === "builder" ?
                                    {
                                        backgroundColor: "white"
                                    } :
                                    {
                                        backgroundColor: "#0C58B0",
                                    }
                                ,
                                disabled: authState.subscription.status && authState.subscription.name === "builder",
                                onClick: () => {onSelected("builder")}
                            }
                        }
                    ]}
                />
            </div>
            <LandingPageBackground />
        </div>

    if(props.text) {
        return (
            <button 
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "1vw"
            }}
            className='button-tertiary'
            onClick={() => {
                setShow(true);
                if(props.setShow)
                    props.setShow(true);
            }}>
                <img 
                    className='Icon-Mid' 
                    src={PremiumActive} 
                    alt="Premium Icon"
                />
                <h4 className='noMargin normal black tertiaryFont'>
                    {props.text}
                </h4>
            </button>
        )
    }

    return (
        <img 
            className='Icon-Mid' 
            src={PremiumActive} 
            alt="Premium Icon"
            onClick={() => {
                setShow(true);
                if(props.setShow)
                    props.setShow(true);
            }}
         />
    )
}

export default PricingDashboard