import React, {useEffect, useState, useContext} from 'react'
import {useParams, useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';

import toastMessage from "../helpers/Toast";
import Validation from "../helpers/Validation"
import UserService from "../services/server/Users"
import { AuthContext } from '../helpers/AuthContext'; 
import Cache from "../services/Cache";

import Input from './components/Input';
import LandingPageBackground from './components/entry/LandingPage/LandingPageBackground';
import CheckboxInput from './components/atoms/CheckboxInput';

function CreateAccount() {

    let { id } = useParams();
    const navigate = useNavigate();
    const {setAuthState} = useContext(AuthContext);
    const location = useLocation();
    const { state } = location;

    useEffect(() => {

        async function fetchData() {            
            await axios.get(process.env.REACT_APP_SERVER_URL + "/users/token", {
                headers: {
                    userToken: id
                }
            }).then(async (resp) => {
                if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                    navigate('/entry');
                } else {
                    let respEmail = resp.data.email;
                    await axios.get(process.env.REACT_APP_SERVER_URL + "/users/checkEmail/" + resp.data.email).then((resp) => { 
                        if(resp.data) {
                            toastMessage(`An account already exists for ${respEmail}`, "error");
                            navigate('/entry');
                        } else {
                            setEmail(respEmail);
                        }
                    })
                }
            })
        }

        fetchData();

    }, [])

    const [username, setUsername] = useState( state?.username || "");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState( state?.password || "")
    const [passwordTwo, setPasswordTwo] = useState(state?.passwordTwo || "")
    const [termsPolicyAccepted, setTermsPolicyAccepted] = useState(state?.termsPolicyAccepted || false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(state?.privacyPolicyAccepted || false);

    async function checkUniqueUsername(value) {
        setUsername(value);

        if(value) {
            let resp = await axios.get(process.env.REACT_APP_SERVER_URL + "/users/checkUsername/" + value)
            return !resp.data;
        }

    }

    const onSubmit = async (e) => {

        e.preventDefault();

        let usernameValid = Validation.username(username);

        let passwordValid = Validation.passwords(password, passwordTwo);

        let uniqueUsername = await checkUniqueUsername(username);

        if(usernameValid && !uniqueUsername) {
            toastMessage(`An Account with the username ${username} already exists!`, "error");
            return;
        }

        if(!privacyPolicyAccepted || !termsPolicyAccepted) {
            toastMessage(`You Must Accept the Terms of Service and the Privacy Policy to use WanderBuilds`, "error");
            return;  
        }

        if(email && (usernameValid && uniqueUsername) && passwordValid) {

            let data = {
                email: email,
                username: username,
                password: password,
                privacyConsent: privacyPolicyAccepted,
                termsOfServiceConsent: termsPolicyAccepted
            }

            const createdUser = await UserService.createUser(data);

            if(createdUser) {
                setAuthState({
                    username: createdUser.username, 
                    id: createdUser.id, 
                    status: true, 
                    createdAt: createdUser.createdAt, 
                    image: createdUser.image, 
                    subscription: createdUser.subscription
                });
                Cache.deleteAllCache();
                navigate('/home')
            } 
        }
    }

    return (
        <div className='central' style={{minHeight: "100vh"}}>
            <LandingPageBackground />
            <form className="PageForm Form" onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h2 className='black tertiaryFont'>Create Account</h2>
                <div className='fields'>
                    <Input 
                        tabIndex="1" 
                        value={email} 
                        placeholder={"Enter your Email"} 
                        label={"Email"}
                        disabled
                    />
                    <Input 
                        tabIndex="2" 
                        value={username} 
                        setValue={checkUniqueUsername} 
                        placeholder={"Enter your Username"}
                        label={"Username"}
                        autoComplete={"off"}
                    />
                    <Input 
                        tabIndex="3" 
                        value={password} 
                        setValue={setPassword} 
                        placeholder={"Enter your Password"} 
                        label={"Password"}
                        autoComplete={"off"}
                        secret
                    />
                    <Input 
                        tabIndex="4" 
                        value={passwordTwo} 
                        setValue={setPasswordTwo} 
                        placeholder={"Re-Enter your Password"} 
                        label={"Confirm Password"}
                        autoComplete={"off"}
                        secret
                    />
                    <CheckboxInput 
                        setCheckbox={(value) => setTermsPolicyAccepted(value)} 
                        checkbox={termsPolicyAccepted}
                        link={"/terms-of-service"}
                        title={"Terms of Service"}
                        username={username}
                        password={password}
                        passwordTwo={passwordTwo}
                        termsPolicyAccepted={termsPolicyAccepted}
                        privacyPolicyAccepted={privacyPolicyAccepted}
                        prevUrl={`/createAccount/${id}`}
                    />
                    <CheckboxInput 
                        setCheckbox={(value) => setPrivacyPolicyAccepted(value)} 
                        checkbox={privacyPolicyAccepted}
                        link={"/privacy-policy"}
                        title={"Privacy Policy"}
                        username={username}
                        password={password}
                        passwordTwo={passwordTwo}
                        termsPolicyAccepted={termsPolicyAccepted}
                        privacyPolicyAccepted={privacyPolicyAccepted}
                        prevUrl={`/createAccount/${id}`}
                    />
                </div>
                <button className="button-quart" onClick={(e) => onSubmit(e)}><h3 className='normal tertiaryFont'>Create</h3></button>
            </form>
        </div>
    )
}

export default CreateAccount