import React, {useState, useRef, useEffect} from 'react'

// SVGs
import View from "../../assets/svg/view.svg";
import Hide from "../../assets/svg/hide.svg";

function Input(props) {

    const [show, setShow] = useState(false);
    const inputRef = useRef(null);

    const { value, placeholder, tabIndex, setValue, label, secret, action, actionText, disabled, autoComplete } = props;
  
    useEffect(() => {
        if(inputRef.current) {
            inputRef.current.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if(inputRef.current) {
                inputRef.current.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if(props.onEnter)
                props.onEnter();
        }
    };


    return (
        <div className='field'>
            {
                label &&
                <div className='fieldLabel'>
                    <h4 className='noMargin tertiaryFont'>{label}</h4>
                </div>
            }
            <div className='inputContainer'>
                <input 
                    ref={inputRef}
                    type={secret ? show ? "text" : "password" : "text"} 
                    tabIndex={tabIndex} 
                    value={value} 
                    placeholder={placeholder} 
                    autoComplete={autoComplete}
                    onChange={(e) => setValue(e.target.value)} 
                    disabled={disabled} 
                    style={ props.background ? {background: props.background} : {}}
                />
                {secret && <img className="secret" alt="icon" src={show ? View : Hide} onClick={() => setShow(!show)}/>}
            </div>
            {action && <p className="action normal" onClick={() => action()}>{actionText}</p>}
        </div>
    )
}

export default Input