import React, {useState, useEffect} from 'react'

import ProjectList from '../../services/ProjectList';

import ListItem from './atoms/ListItem';
import FilterTags from './FilterTags';
import Loading from './atoms/Loading';

function ProjectsBoard(props) {

  const [filterValue, setFilterValue] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if(props.projects) {
      setProjects(props.projects);
      setFilterValue({sort: "newest", visibility: "all"});
      onChooseFilter("newest", -1);
    }
  }, [props.projects])

  const onChooseFilter = (value, key) => {
    let filterValueUpdated = null;
    switch (key) {
      case 0:
          filterValueUpdated = {...filterValue, sort: value};
        break;
      case 1:
        filterValueUpdated = {...filterValue, visibility: value};
        break;
      default:
        filterValueUpdated = {sort: value, visibility: "all"}
        break;
    }
    let updatedProjects = ProjectList.sortProject(props.projects, filterValueUpdated);
    setProjects(updatedProjects);
    setFilterValue(filterValueUpdated)
  }

  const onStatsClick = (project) => {
    props.onStatsClick(project);
  }

  if(props.isLoading)
    return (  
      <Loading/>
    )

  return (
    <div className='ProjectsBoard'>
      {
        filterValue?.sort &&
        <FilterTags 
          onClick={onChooseFilter}
          tags={[
            {
              label: "Sort by",
              value: filterValue.sort,
              options: [
                {
                  label: "Newest",
                  value: "newest",
                },
                {
                  label: "Oldest",
                  value: "oldest"
                },
                {
                  label: "Most Played",
                  value: "mostplayed",
                },
                {
                  label: "Most Favorited",
                  value: "mostFavorited",
                }
              ]
            },
            {
              label: "Visibility",
              value: filterValue.visibility,
              options: [
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Public",
                  value: "public"
                },
                {
                  label: "Unlisted",
                  value: "unlisted",
                },
                {
                  label: "Private",
                  value: "private",
                }
              ]
            }
          ]}
        />
      }
        <div className='ProjectList'>
            {
                projects && (projects.length > 0 ?  
                projects.map((value, key) => {
                   return <ListItem key={key} project={value} onStatsClick={onStatsClick}/>
                }) : <h4 className='centerText'>No Projects</h4>)

            }
        </div>
        
    </div>
  )
}

export default ProjectsBoard