import React, {useState, useEffect, useContext} from 'react'

import Projects from "../../services/server/Projects"

import { AuthContext } from '../../helpers/AuthContext';

import Header from './Header';
import AnalyticsBoard from './AnalyticsBoard';
import ProjectsBoard from './ProjectsBoard';

import Logo from "../../assets/svg/Logo.svg";

function Studio() {

    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);
    const [selectedProject, setSelectedProject] = useState(null);
    const {authState} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let response = await Projects.getProjectsByUser(authState.id);
            setProjects(response);
            setIsLoading(false);
        }

        fetchData();
    }, [authState])

  const onStatsClick = (project) => {
    setSelectedProject(project);
    setActive(2);
  }

  return (
    <>    
        <Header 
            subOptions={
              [
                {
                  name: "Content"
                },
                {
                  name: "Overall Stats",
                },
                ...selectedProject ? [{
                  name: selectedProject.name,
                }] : [],
              ]
            }
            subOptionActive={active}
            setSubOptionActive={(value) => setActive(value)}
          />
        <div className='Contents' style={{paddingTop: "14vh"}}>
          {
            active === 0 &&
            <ProjectsBoard projects={projects} onStatsClick={onStatsClick} isLoading={isLoading}/>
          }
          {
            active === 1 &&
            <AnalyticsBoard />
          }
          {
            active === 2 &&
            <AnalyticsBoard project={selectedProject}/>
          }
        </div>
    </>
  )
}

export default Studio