import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class CommentsLikes {

    static async getCommentLikesByCommentId(id) {
        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/commentsLikes/comment/" + id, 
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async createCommentLike(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/commentsLikes", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async updateCommentLike(data) {

        let response = await axios.patch(
            process.env.REACT_APP_SERVER_URL + "/commentsLikes", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}
