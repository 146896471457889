export default class Cache {

    static map = new Map();

    static checkCached(key) {
        if (Cache.map.has(key)) {
            const cacheEntry = Cache.map.get(key);
            if(cacheEntry.expiration < Date.now()) {
                Cache.map.delete(key);
                return false;
            } else {
                return cacheEntry.data;
            }
        }
    }

    static setCache(key, value) {
        // Store the result in the cache
        const now = new Date();
        Cache.map.set(key, 
        {
            data: value,
            expiration: now.setMinutes(now.getMinutes() + 10), // cache expires in 10 minutes
        });
    }

    static deleteCache(key) {
        if(Cache.map.has(key)) {
            Cache.map.delete(key);
        }
    }

    static deleteAllCache() {
        Cache.map.clear();
    }
}