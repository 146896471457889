import React, {useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";

import Images from '../../services/server/Images';
import UserIcon from "../../assets/svg/UserIcon.svg";
import IconSwitcher from './atoms/IconSwitcher';

function Footer(props) {

    let navigate = useNavigate();

    const [image, setImage] = useState(null);
    const [active, setActive] = useState(props.default);
    const iconRef = useRef(null);

    useEffect(() => {
        setActive(props.default);
    }, [props.default])

    const go = (order, value) => {
        setActive(order);
        navigate("/" + value, 
        {
            state: {item: order}
        });
    }

    const fetchImage = async (image) => {
        let imageData = await Images.getUserImage(image);
        const imageBlob = new Blob([imageData])
        setImage(imageBlob);
    }

    return (
        <div className='Footer'>
            {props.items.map((value, key) => {

                if(!image && value.image && value.image !== "no-user") {
                    fetchImage(value.image);
                }

                if(value.swipeButton) {
                    return <IconSwitcher key={key} swipeUp={value.swipeUp} swipeDown={value.swipeDown} onClick={value.onClick} />
                }

                return (
                <button 
                    key={key} 
                    ref={iconRef} 
                    className={"button-none"}
                    onClick={value.onClick ? value.onClick : () => go(key, value.name)}
                >
                    <div  
                        className={'Item'} 
                    >
                            <img 
                                className={active === key ? value.style.default + " " + value.style.active : value.style.default} 
                                src={value.image ? (image ? URL.createObjectURL(image) : UserIcon) : (value.icon && (active === key ? value.icon?.active : value.icon?.inactive))} 
                                alt={value.name} 
                            />
                        {value.displayText && <h6 className='noMargin normal'>{value.displayText}</h6>}
                    </div>
                </button>
                )
            })}
        </div>
  )
}

export default Footer