import React, {useState, useEffect} from 'react'

import ProjectList from '../../../services/ProjectList';

import Thumbnail from "../Thumbnail";
import Loading from './Loading';

function ProjectCarousal(props) {

  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if(props.projects && props.projects.length >= 1) {
      let sortedProjects = ProjectList.sortNewest(props.projects);
      setProjects(sortedProjects);
    }
  }, [props.projects])

  return (
    <div className='ProjectCarousalContainer'>
      <div className='ProjectCarousalTitle'>
        <h4 className='noMargin'>Favorites</h4>
      </div>
      <div className='CarousalContainer'>
        {
          props.isLoading ? <Loading /> :
          <div className={projects?.length >= 1 ? 'CenterCarousal' : "InfoDisplay"}>
            { projects && projects?.length >= 1 ?
              projects.map((value, key) => {
                return <Thumbnail value={value} key={key}/>
              })
              :
              <p>You haven't favorited any games yet</p>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default ProjectCarousal