import React, {useState, useContext, useEffect} from 'react'
import toastMessage from "../../helpers/Toast";
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../helpers/AuthContext';

import Projects from "../../services/server/Projects"
import Files from "../../services/server/Files"
import Images from "../../services/server/Images"
import Share from "../../services/Share";

import Select from "./atoms/Select"

import Input from "./Input"
import FileInput from "./atoms/FileInput";
import PricingDashboard from './PricingDashboard';
import Modal from './entry/Modal';
import TwoOptionForm from './atoms/TwoOptionForm';

import {maxSize} from "../../helpers/Constants"
import Validation from "../../helpers/Validation"
import CopyInactive from '../../assets/svg/Copy-inactive.svg'
import CopyActive from '../../assets/svg/Copy-active.svg'

function ProjectDetails(props) {

    const {authState} = useContext(AuthContext);

    const navigate = useNavigate();

    const [name, setName] = useState({savedName: "", currName: ""});
    const [image, setImage] = useState({saved: null, current: null});
    const [visibility, setVisibility] = useState({ saved: "private", current: "private"});
    const [changes, setChanges] = useState(false);
    const [loadingImage, setLoadingImage] = useState(true);
    const [saving, setSaving] = useState(false);
    const [showPricing, setShowPricing] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [copy, setCopy] = useState(CopyInactive);

    useEffect(() => {

        async function fetchData() {

            setName({savedName: props.project.name, currName: props.project.name})
            setVisibility({saved: props.project.visibility, current: props.project.visibility})
            
            if(props.project.image) {
                let imageData = await Images.getProjectImage(props.project.image);
                const imageBlob = new Blob([imageData])
                setImage({saved: imageBlob, current: imageBlob});
            }
            setLoadingImage(false);
        }

        if(props.project) {
            fetchData()
        }

        return () => {
            setImage({saved: null, current: null});
            setName({savedName: "", currName: ""});
            setVisibility({saved: "private", current: "private"});
            setLoadingImage(true);
        }
    }, [props.project])

    useEffect(() => {
        setChanges(
            name.savedName !== name.currName 
            || 
            image.saved !== image.current
            ||
            visibility.saved !== visibility.current
            );
    }, [name, image, visibility])

    const createProject = async () => {

        setSaving(true);

        if(!name.currName) {
            setSaving(false);
            toastMessage("Name Field Must have a value", "error")
            return;
        }

        if(Validation.isObscene(name.currName)) {
            setSaving(false);
            toastMessage("Project Title Must NOT Contain Profanity!", "error")
            return;
        }
        
        let requestBody = {
            name: name.currName,
            userId: authState.id
        }

        let response = await Projects.createProject(requestBody);
        
        if(!response) {
            setSaving(false);
            return;
        }

        if(response.membership) {
            setShowPricing(true);
            setSaving(false);
            return;
        }

        setSaving(false);
        navigate("/create/" + response.entityId);
        
    }

    if(showPricing)
        return <PricingDashboard show={showPricing} onExit={() => setShowPricing(false)}/>

    if(!props.project) 
        return (
            <div className='SectionPage'>
                <div className='SectionTitle'>
                    <h2 className='textMargin centerText black'>New Project</h2>
                </div>
                <div className='Section fullHeight'>
                    <div className='SectionContentTitle'>
                        <h4 className='textMargin normal noMargin'>Name</h4>
                    </div>
                    <div className='SectionContent'>
                        <Input value={name.currName} placeholder={"Enter Project Name..."} tabIndex={0} setValue={(value) => setName({...name, currName: value})}/>
                    </div>
                    <div className='SectionContentTitle'>
                        <h4 className='textMargin normal noMargin'>Language</h4>
                    </div>
                    <div className='SectionContent'>
                        <Input value={"Javascript"} tabIndex={1} disabled />
                    </div>
                </div>
                <div className='Section'>
                    <div className='SectionSubmit'>
                        <button className={!saving ? 'button-secondary' : 'button-disabled'} onClick={createProject} disabled={saving}><h4>{saving ? "Creating Project..." : "Create Project"}</h4></button>
                    </div>
                </div>
            </div>
        )

    const saveChanges = async () => {

        setSaving(true);

        if(!name.currName) {
            toastMessage("Name Field Must Have a Value", "error");
            setSaving(false);
            return;
        }

        if(Validation.isObscene(name.currName)) {
            setSaving(false);
            toastMessage("Project Title Must NOT Contain Profanity!", "error")
            return;
        }

        let imageKey = null;
        if(image.current !== image.saved) {
            // upload image via server
            if(image.current) {
                let response = await Images.uploadThumbnailImage(image.current);
                if(response) {
                    imageKey = response.name;
                } else {
                    setSaving(false);
                    setImage({...image, current: image.saved})
                    return;
                }
            }
        }


        let requestBody = {
            id: props.project.id,
            name: name.currName,
            ...(imageKey ? {image: imageKey} : {}) ,
            visibility: visibility.current,
            userId: authState.id
        }

        let response = await Projects.updateProject(requestBody);

        if(response?.membership) {
            setShowPricing(true);
            setSaving(false);
            return;
        }

        if(response?.message) {
            setName({...name, savedName: name.currName})
            setVisibility({...visibility, saved: visibility.current})
            setImage({...image, saved: image.current})
        }

        setSaving(false);

    }

    const undoChanges = () => {
        setName({...name, currName: name.savedName})
        setImage({...image, current: image.saved})
        setVisibility({...visibility, current: visibility.saved})
    }

    const updateImage = (file) => {
        if(file) {
            if(file.size > maxSize) {
                toastMessage("Image Size Exceeds 5MB limit", "error");
            } else {
                setImage({...image, current: file})
            }
        }
    }

    const onSelectChange = (value) => {
        if((value === "public" || value === "unlisted") && !image.saved && !image.current) {
            toastMessage("Each Sketch Must Have Thumbnail", "error") 
            return false;
        }   
        return true;
    }

    const onDeleteProject = async () => {
        await Projects.delete(props.project.id);

        navigate("/home", {
            state: {item: 3}
        })
    }

  return (
        <div className='Contents fullPageNoHeader'>
        <div className='DesktopPage SectionPage'>
            <div className='SectionHeader'>
                {!saving ? <button className={(changes) ? 'button-inverse' : 'button-inverse-disabled'} onClick={() => undoChanges()} disabled={!changes}><h4>UNDO CHANGES</h4></button> : null}
                <button className={(changes && !saving) ? 'button-secondary' : 'button-disabled' } onClick={() => saveChanges()} disabled={!changes}><h4>{saving ? "SAVING..." : "SAVE"}</h4></button>
            </div>
            <div className='SectionTitle'>
                <h2 className='textMargin centerText black'>Project Details</h2>
            </div>
            <div className="Section">
                <div className='SectionTitle'>
                    <h4 className='noMargin normal'>Name</h4>
                </div>
                <div className='SectionContent'>
                    <Input value={name.currName} placeholder={"Enter Project Name..."} tabIndex={0} setValue={(value) => setName({...name, currName: value})} disabled={saving}/>
                </div>
            </div>
            {
            authState.subscription.status === "complete" &&  
            <div className="Section">
                <div className='SectionTitle'>
                    <h4 className='noMargin normal' style={{width: "fit-content"}}>Thumbnail</h4>
                    <FileInput name={"project"} onChange={updateImage} disabled={saving}/>
                </div>
                {!loadingImage ? 
                    (
                        image?.current ?
                            <div className='SectionContent'>
                                <img className="ThumbnailDemo" src={URL.createObjectURL(image.current)} alt={image.current.name ? image.current.name : "Image"} />
                            </div> 
                            : 
                            <div className='SectionContent'>
                                <p>No Image</p>
                            </div> 
                    ) 
                    : 
                    (
                        <div className='SectionContent'>
                            <p>Loading Image...</p>
                        </div>
                    )
                }
            </div>}
            {
                authState.subscription.status ? 

                <div className="Section">
                    <div className='SectionTitle'>
                        <h4 className='noMargin normal'>Share</h4>
                        <PricingDashboard setShow={() => (setShowPricing(true))}/>
                    </div>
                    <div className='SectionContent' style={{
                        gap: "2vw",
                    }}>
                        <Input value={`${process.env.REACT_APP_WEBSITE_URL}/game/${props.project.entityId}`} disabled/> 
                        <img 
                            className="Icon IconBorder" 
                            src={copy} alt="Copy Link"
                            onClick={() => Share.shareLink(name ? name.savedName : null, props?.project?.image ? props.project.image : null, `${process.env.REACT_APP_WEBSITE_URL}/game/${props.project.entityId}`)}
                            onMouseOver={() => setCopy(CopyActive)} 
                            onMouseOut={() => setCopy(CopyInactive)}
                            onTouchStart={() => setCopy(CopyActive)} 
                            onTouchEnd={() => setCopy(CopyInactive)}
                        />
                    </div>
                </div>
                : 
                <></>
            }
            <div className="Section">
                <div className='SectionTitle'>
                    <h4 className='noMargin normal'>Visibility</h4>
                    <PricingDashboard setShow={() => (setShowPricing(true))}/>
                </div>
                <div className='SectionContent'>
                    {
                        authState.subscription.status ? 
                        <Select 
                            id={"visibility"} 
                            selected={visibility.current} 
                            options={["private", "unlisted", "public"]} 
                            setSelected={(value) => setVisibility({...visibility, current: value})} 
                            onChange={onSelectChange}
                            disabled={saving}
                        />
                        :
                        <Input value={"Private"} tabIndex={1} disabled />
                    }
                </div>
            </div>
            <div className="Section">
                <div className='SectionTitle'>
                    <h4 className='noMargin normal'>Settings</h4>
                </div>
                <div className='SectionContent'>
                    <button 
                    className="button-inverse" 
                        style={{
                                border: "1px solid var(--primary)",
                                width: "100%"
                        }}
                        onClick={() => setShowDeleteModal(true)}
                    >

                        <p className='primary'>
                            Delete Project
                        </p>
                    </button>
                    <Modal 
                        title={"Delete Project"}
                        show={showDeleteModal}
                        setShow={() => {setShowDeleteModal(false)}}
                        content={<TwoOptionForm 
                                    title={`Are you sure you want to delete the project "${name.currName}"`}
                                    subtitle="This action is irreversible."
                                    option1="cancel"
                                    option2="Yes, Delete"
                                    onOptionOne={() => setShowDeleteModal(false)}
                                    onOptionTwo={() => onDeleteProject()}
                                />}
                    />
                </div>
            </div>
        </div>
        </div>
  )
}

export default ProjectDetails