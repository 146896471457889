import React, {useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../../helpers/AuthContext';

import Input from '../Input'
import ImageInput from './ImageInput';

import Users from '../../../services/server/Users';
import Images from '../../../services/server/Images';
import toastMessage from '../../../helpers/Toast';

function UserDetails() {

    let {authState, setAuthState} = useContext(AuthContext);

    const [username, setUsername] = useState(authState.username);
    const [image, setImage] = useState(authState.image);
    const [imageSaving, setImageSaving] = useState(false);

    const onSetUsername = async () => {
        let response = await Users.updateUser({username: username});
        if(response) {
            localStorage.setItem("accessToken", response.token)
            setAuthState({...authState, username: username});
            toastMessage("Username Updated", "success");
        }
    }

    const onSetImage = async (value) => {

        setImageSaving(true);

        let imageKey = null;

        if(value) {
            let response = await Images.uploadProfileImage(value);
            if(response) {
                imageKey = response.name;
                localStorage.setItem("accessToken", response.token)
                setImage(imageKey);
                setAuthState({...authState, image: imageKey});
                setImageSaving(false);
            } else {
                setImageSaving(false)
            }
        }
    }

    return (
        <div className='UserDetails'>
            <ImageInput userImage={image} onChange={onSetImage} disabled={imageSaving}/>
            <div className='UserInfoContainer'>
                <div className='UsernameInfoContainer'>
                    <Input value={username} setValue={(value) => setUsername(value)}/>
                    <button className='button-secondary' onClick={onSetUsername}><h4>Update Username</h4></button>
                </div>
            </div>
        </div>
    )
}

export default UserDetails