import React from 'react'

function TwoOptionForm(props) {
  return (
    <div
        style={{
            display: "flex",
            flexDirection:"column", 
            gap:"5vh",
            height: "100%",
            flex: 1,
        }}
    >
        <div 
            style={{
                display: "flex",
                flexDirection:"column", 
                gap:"10px"
            }}
        >
            <p>{props.title}</p>
            <p>{props.subtitle}</p>
        </div>
        <div 
            style={{
                display: "flex",
                flexDirection:"row", 
                gap:"10px",
                justifyContent: "flex-end"
            }}
        >
            <button className='button-disabled' onClick={() => props.onOptionOne()}>{props.option1}</button>
            <button className='button-primary' onClick={() => props.onOptionTwo()}>{props.option2}</button>
        </div>
    </div>
  )
}

export default TwoOptionForm