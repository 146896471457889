import React from 'react';

const ContextMenu = ({ top, left, onCopy, onCut, onPaste, onUndo, onRedo, onSelectAll, onClose }) => {
  return (
    <div 
      style={{ 
        position: 'absolute', 
        top: `calc(${top}px)`, 
        left: `${left}px`, 
        backgroundColor: '#fff', 
        border: '1px solid #ccc', 
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)', 
        display: "flex", 
        flexDirection: "column",
        zIndex: 1000 
      }} 
      onMouseLeave={onClose}
    >
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        <li><button className="button-menu" onClick={onCopy}>Copy</button></li>
        <li><button className="button-menu" onClick={onCut}>Cut</button></li>
        <li><button className="button-menu" onClick={onPaste}>Paste</button></li>
        <li><button className="button-menu" onClick={onUndo}>Undo</button></li>
        <li><button className="button-menu" onClick={onRedo}>Redo</button></li>
        <li><button className="button-menu" onClick={onSelectAll}>Select All</button></li>
      </ul>
    </div>
  );
};

export default ContextMenu;
