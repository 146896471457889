import React from 'react'

function LandingPageBackground() {
  return (
          <div className='Background'>
            <div className='BackgroundItem'>
              <h2 className='noMargin breakWord normal tertiaryFont' style={{whiteSpace: "pre-wrap", color: "rgba(225, 225, 225, 1)"}}>
                {
                `function (x) {
  x = x + x;
  drawCircle(100, 100, 100);
}
`
                }               
              </h2>
            </div>
            <div className='BackgroundItem'>
              <h2 className='noMargin breakWord normal tertiaryFont' style={{whiteSpace: "pre-wrap", color: "rgba(225, 225, 225, 1)"}}>
                {
                `function (x) {
  x = x + x;
  drawCircle(100, 234, 199);
}
`
                }               
              </h2>
            </div>
            <div className='BackgroundItem'>
              <div className='BackgroundBall Ball1'></div>
              <div className='BackgroundBall Ball2'></div>
              <div className='BackgroundBall Ball3'></div>
            </div>
          </div>
  )
}

export default LandingPageBackground