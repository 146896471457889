import React, {useState, useContext, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';

import Projects from "../services/server/Projects";

/* HELPERS */
import { AuthContext } from '../helpers/AuthContext';

import GameList from './components/GameList';
import Footer from './components/Footer';
import Header from './components/Header';
import ProjectDetails from './components/ProjectDetails';
import NoResults from './components/atoms/NoResults';

import HomeIcon from '../assets/svg/Navigation/Home-active.svg';
import Play from "../assets/svg/Navigation/Play-active.svg";
import Create from "../assets/svg/Navigation/Create-active.svg";
import StudioActive from "../assets/svg/Navigation/Studio-active.svg";

import HomeInactive from "../assets/svg/Navigation/Home-inactive.svg";
import PlayInactive from "../assets/svg/Navigation/Play-inactive.svg";
import StudioInactive from "../assets/svg/Navigation/Studio-inactive.svg";
import CreateInactive from "../assets/svg/Navigation/Create.svg";
// Images
import toastMessage from '../helpers/Toast';
import UserPage from './components/UserPage';
import Studio from "./components/Studio";
import Loading from './components/atoms/Loading';

function Home() {

  const {authState} = useContext(AuthContext);
  const location = useLocation();
  const { state } = location;
  let navigate = useNavigate();

  const [nav, setNav] = useState(0);
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let response = await Projects.getProjects();
      setGames(response);
      setIsLoading(false);
    }
    fetchData();
  }, []) 

  useEffect(() => {
    if(state && state.item) {
      setNav(state.item);
    }
  }, [state])

  const onPlay = () => {
    if(games.length >= 1) {
      navigate("/game/" + games[0].entityId);
    } else {
      toastMessage("No Games to Play", "error");
    }
  }

  return (
    <div className='Page column'>
      {nav === 0 && 
      <>      
          <Header 
            endIcons={[
              {
                type: "premium"
              },
              {
                type: "search",
                list: games.map((value) => {return {name: value.name, entityId: value.entityId}})
              }
            ]}
          />
          <div className='Contents'>
            {isLoading ? 
              <Loading /> : games?.length >= 1 ? 
              <GameList games={games} view={true} />
              :
              <NoResults message={"No Games Available"} />
            }
          </div>
      </>
      }

      {
        nav === 2 &&
        <ProjectDetails /> 
      }

      {
        nav === 3 && 
          <Studio mode={"details"} />
      }

      {
        nav === 4 && 
          <UserPage setNav={() => setNav(0)}/>
      }
      
      <Footer
        default = {nav}
        items = {[
          {
            name: 'home',
            displayText: 'Home',
            style: {
              active: null,
              default: 'Icon-Reduced'
            },
            icon: {
              active: HomeIcon,
              inactive: HomeInactive
            },
            onClick: () => { setNav(0) }
          },
          {
            name: 'play',
            displayText: 'Play',
            style: {
              active: null,
              default: 'Icon-Reduced'
            },
            icon: {
              active: Play,
              inactive: PlayInactive
            },
            onClick: onPlay
          },
          {
            name: 'create',
            style: {
              active: null,
              default: 'Icon-Large'
            },
            icon: {
              active: Create,
              inactive: CreateInactive
            },
            onClick: () => {
              setNav(2);
            }
          },
          {
            name: 'studio',
            displayText: 'Studio',
            style: {
              active: null,
              default: 'Icon'
            },
            icon: {
              active: StudioActive,
              inactive: StudioInactive
            },
            onClick: () => {
              setNav(3);
            }
          },
          {
            name: 'user',
            displayText: "You",
            style: {
              active: "UserActive",
              default: "Round"
            },
            image: authState.image ? authState.image : "no-user",
            onClick: () => {
              setNav(4);
            }
          }
        ]}
      />    
    </div>
  )
}

export default Home