import axios from "axios";
import toastMessage from "../../helpers/Toast";
import { useContext } from "react";
import { AuthContext } from "../../helpers/AuthContext";

export default class Subscription {

    static async createCheckoutSession(data) {
        await axios.post(
            process.env.REACT_APP_SERVER_URL + "/subscriptions/create-checkout-session",
            data,
            {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            }
        )
        .then((response) => {
            window.location = response.data.url;
        }).catch((e) => {
            toastMessage(e, "error");
        })
    }

    static async cancelSubscription() {

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/subscriptions/cancel",
            {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                }
            }
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        toastMessage(response.data.message, "success");
        localStorage.setItem("accessToken", response.data.token);
        return response.data;
    }
}