const DateService = {
  formatRelativeTime(date) {
    const now = new Date()
    const then = new Date(date);
    const diff = now.getTime() - then.getTime()
    const rtf = new Intl.RelativeTimeFormat('en', { style: 'narrow' })
    const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
    const diffInHours = Math.floor(diff / (1000 * 60 * 60))
    const diffInMinutes = Math.floor(diff / (1000 * 60))

    if (diffInDays >= 30) {
      return rtf.format(-Math.floor(diffInDays / 30), 'month')
    } else if (diffInDays >= 7) {
      return rtf.format(-Math.floor(diffInDays / 7), 'week')
    } else if (diffInDays >= 1) {
      return rtf.format(-diffInDays, 'day')
    } else if (diffInHours >= 1) {
      return rtf.format(-diffInHours, 'hour')
    } else {
      return rtf.format(-diffInMinutes, 'minute')
    }
  },
  formatDate(dateString) {
    const dateObj = new Date(dateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return [month, day, year];
  },
  formatNiceDate(dateString) {
    const dateObj = new Date(dateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return month + " " + day + " " + year;
  }
}

export default DateService;