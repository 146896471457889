import React, {useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import Images from '../../services/server/Images';
import Loading from './atoms/Loading';

function Thumbnail(props) {

    const [image, setImage] = useState(null);
    const [userImage, setUserImage] = useState(null);

    const titleRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const handleMouseEnter = () => {
        if (titleRef.current) {
        setIsOverflowing(titleRef.current.scrollWidth > titleRef.current.clientWidth);
        }
    };

    const handleMouseLeave = () => {
        setIsOverflowing(false);
    };

    useEffect(() => {

        const fetchData = async () => {
            let imageData = await Images.getProjectImage(props.value.image);
            const imageBlob = new Blob([imageData])
            setImage(imageBlob);
        }

        if(props.value.image) {
            fetchData();
        }

    }, [props.value.image])

    useEffect(() => {

        const fetchUserImage = async (image) => {
            let imageData = await Images.getUserImage(image);
            const imageBlob = new Blob([imageData])
            setUserImage(imageBlob);
        }

        if(props?.value?.user?.image) {
            fetchUserImage(props.value.user.image);
        }

    }, [props.value.user.image])

    let navigate = useNavigate();

    const onClick = () => {
        navigate('/game/' + props.value.entityId);
    }
    
    return (
        <div className='Thumbnail' onClick={() => onClick()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.value.image 
                ? 
                image 
                    ? 
                    <img className='GameImage' width="100%" src={URL.createObjectURL(image)} alt={props.value.iamge} /> 
                    : 
                    <Loading type={"component"} /> : 
                <div className='GameImage' style={{backgroundColor: '#eeeeee'}}></div>
            }
            <div className='Stats'>
                <div className='Game'>
                    <h4 className={`ThumbnailTitle noMargin normal ${isOverflowing ? 'overflow' : ''}`} ref={titleRef}>{props.value.name}</h4>
                    <h6 className='noMargin central grey normal'>{props.value.projectUserPlays >= 1 ? props.value.projectUserPlays + " " : "No "}{((props.value.projectUserPlays > 1) || props.value.projectUserPlays === 0) ? "Plays" : "Play"}</h6>
                </div>
            </div>
            <div className='User' style={{position: "absolute", left: "1vh", top: "1vh", border: "2px solid rgba(0,0,0,.08)", borderRadius: "5vh"}}>
                {props.value.user.image ?
                    userImage ? <img className='ThumbnailUserIcon central'
                                    src={URL.createObjectURL(userImage)} alt="User" /> : <Loading type={"circular"}/>
                        :
                    <div className='ThumbnailUserIcon central'></div>
                }
            </div>
        </div>
    )
}

export default Thumbnail