import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Comments {

    static async getFollowersByUserId(userId) {

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/followers/" + userId, 
            {
                headers: {
                    accessToken: localStorage.getItem("accessToken")
                },
            }
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;       
    }

    
    static async getUserFollowStatus(userId) {

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/followers/user/" + userId, 
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;       
    }

    static async createFollower(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/followers", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async updateFollower(data) {

        let response = await axios.patch(
            process.env.REACT_APP_SERVER_URL + "/followers", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}
