/* THIRD-PARTY FUNCTIONS */
import {useState, useEffect, useRef} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


/* PAGES */
import Home from "./pages/Home.js";
import Entry from './pages/Entry.js';
import CodingTool from './pages/CodingTool.js';
import CreateAccount from './pages/CreateAccount.js';
import ResetPassword from './pages/ResetPassword.js';
import Game from "./pages/Game.js"

/* HELPERS */
import { AuthContext } from "./helpers/AuthContext.js";
import { PrivateRoute } from './helpers/PrivateRoute.js';

import "./assets/scss/Global.scss";
import Loading from './pages/components/atoms/Loading.js';
import Authentication from './services/server/Authentication.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsOfService from './pages/TermsOfService.js';

function App() {

  const [loading, setLoading] = useState(true);
  const [authState, setAuthState] = useState({username: "", id: 0, status: false});
  const hasFetchedData = useRef(false);

  useEffect(() => {

      async function fetchData() {

        let auth = await Authentication.check();

        if(!auth || auth.error) {
          setAuthState(prevState => ({ ...prevState, status: false }));
        } else {
          setAuthState(prevState => ({
            username: auth.username, 
            id: auth.id, 
            status: true, 
            createdAt: auth.createdAt, 
            image: auth.image,
            subscription: auth.subscription
          }))
        }
        setLoading(false);
      }

      const accessToken = localStorage.getItem("accessToken");

      if(accessToken && !hasFetchedData.current) {
        fetchData();
        hasFetchedData.current = true; // Set the ref to true after fetching
      } else if (!accessToken){
        setLoading(false);
      }
  }, [])

    if(loading) {
      return <Loading type="page" />
    }

  return (
    <>
    <AuthContext.Provider value={{authState, setAuthState}}>
      <Router>
        <Routes>
          <Route path='/game/:entityId' element={<Game/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-of-service' element={<TermsOfService/>} />
          <Route path="/createAccount/:id" element={<CreateAccount/>} />
          <Route path="/resetPassword/:id" element={<ResetPassword/>} />
          <Route path="/entry" element={<Entry />} />
          <Route path='/create/:entityId' element={<PrivateRoute/>}>
            <Route path='/create/:entityId' element={<CodingTool/>} />
          </Route>
          <Route path='/game/:entityId' element={<PrivateRoute/>}>
          </Route>
          <Route path='/home' element={<PrivateRoute />}>
            <Route path='/home' element={<Home/>} />
          </Route>
          <Route path="*" element={<Navigate to={authState?.status ? '/home' : "/entry"}/>}/>
        </Routes>
      </Router>
    </AuthContext.Provider>
    <ToastContainer />
    </>
  );
}

export default App;
