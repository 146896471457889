import React, {useState} from 'react'
import axios from 'axios'

import toastMessage from "../../../helpers/Toast";
import Validation from '../../../helpers/Validation';

import Input from '../Input';

function SignUp(props) {

    const [email, setEmail] = useState("");

    async function onSignUp(e) {

        if(e)
            e.preventDefault();

        if(!Validation.email(email))
            return;

        if(email) {
            let data = {email: email}
                
            axios.post(process.env.REACT_APP_SERVER_URL + '/users/signUp', data).then((resp) => {
                if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                } else {
                    toastMessage("An email has been sent to " + resp.data.email, "success");
                }
            })
        }
    }

    return (
        <>
            <div className='EntryContentsContainer'>
                <div className='FormTitle'>
                    <h1 className='tertiaryFont centerText'>Start Coding <br/>Now!</h1>
                </div>
                <form className='Form EntryForm' onSubmit={(e) => onSignUp(e)}>
                    <Input 
                        tabIndex="1" 
                        value={email} 
                        setValue={(e) => setEmail(e?.trim())} 
                        placeholder={"Enter your Email"} 
                        label={"Email"}
                    />
                </form>
            </div>
            <div className='LandingPageFooter'>
                <button className='button-quart' onClick={(e) => onSignUp(e)}>
                    <h2 className='tertiaryFont'>Sign Up</h2>
                </button>
                <h5 className='underlinedHover normal centerText grey' onClick={() => props.setStep(1)}>Already Have an Account? Log In</h5>
            </div>
        </>
    )
}

export default SignUp