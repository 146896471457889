import React, {useState, useEffect} from 'react'

function FilterTags(props) {

    const [selected, setSelected] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if(!selectedOption && props.tags?.length >= 1 && props.tags[0]?.options?.length >= 1) {
            setSelectedOption(props.tags.map((tag => tag.options[0].value)));
        }
    }, [props.tags])

    const onTagClick = (value, key) => {
        props.onClick(value);
        setSelected(key);
    }

    const handleSelectChange = (e, key) => {
        setSelectedOption((selectedOption) => selectedOption.map((item, index) => index === key ? e.target.value : item));
        props.onClick(e.target.value, key);
    }

        return (
            <div className='FilterTagsContainer'>
                {
                    props.tags.map((value, key) => {
                        if(value.options.length >= 1 && selectedOption?.length >= 1) {
                            return (
                                <select key={key} className={"SelectMini Tag"} value={selectedOption[key]} onChange={(e) => handleSelectChange(e, key)}>
                                    {   
                                        value.options.map(option => (
                                            <option key={option.value} value={option.value} className='Tag Option'>{option.label}</option>
                                        ))
                                    }
                                </select>
                            )
                        }

                        return (
                            <div key={key} className={selected === key ? 'TagSelected' : 'Tag'} onClick={() => onTagClick(value.value, key)}>
                                <h4 className='noMargin normal'>{value.title}</h4>
                            </div>
                        )
                    })
                }
            </div>
        )
}

export default FilterTags