import React, {useState, useEffect} from 'react'

import { Line } from 'react-chartjs-2';
import { LinearScale } from 'chart.js/auto';

function Graph(props) {

    const options = ({
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: {
                stepSize: 1,
                beginAtZero: true,
                type: 'linear',
                ticks: {
                    callback: function(value, index, values) {
                        if (index % 2 === 0) {
                            return value;
                        }
                    }
                },
            },
            x: {
                ticks: {
                    autoSkip: false,
                    callback: (value, index, values) => {
                        if (index === 0 || index === props.data.labels.length - 1) {
                            let newDate = new Date(props.data.labels[index]);
                            let dateFormat = newDate.toLocaleDateString("en-US", { month: "short", day: "numeric" });         
                            return dateFormat;               
                        }
                        return '';
                    },
                },
                grid: {
                    display: true,
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                    lineWidth: 0,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    })

    const [data, setData] = useState(null);

    useEffect(() => {
        if(props.data) {
            setData({
                labels: props.data.labels,
                datasets: [
                    {
                        label: "Plays",
                        data: props.data.data,
                        borderColor: '#00C608',
                        backgroundColor: '#00C608',
                        pointRadius: 2.5,
                        pointHoverRadius: 10,
                        borderWidth: 2,
                        lineTension: 0.5,      
                    }
                ]
            })
        }
    }, [props.data])

    return (
        <div className='GraphContainer'>
            <div className='GraphBox'>
                <div className='GraphTitle'>
                    <h4 className='normal noMargin'>{props.title}</h4>
                    <h3 className='noMagin'>{props.number}</h3>
                </div>
                {
                    data &&
                    <div className='Graph'>
                        <Line data={data} options={options} />
                    </div> 
                }
            </div>
        </div>
    )
}

export default Graph