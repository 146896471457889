import React from 'react'

import FileLine from "./FileLine"

function FileExplorer(props) {

  return (
    <div className='FileExplorer'>
      {props.files.map((value, key) => {
        return <FileLine 
          key={key} 
          name={value.name} 
          openFile={() => props.openFile(value)}
          onDeleteFile={() => props.onDeleteFile(value)}
          />
      })}
    </div>
  )
}

export default FileExplorer