import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class ProjectFavorites {

    static async getFavoritesCountByProjectId(projectId) {

        try {       
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/projectfavorites/projectCount/" + projectId,
                {
                    headers: {
                    accessToken: localStorage.getItem("accessToken")
                },
                }
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;     
        } catch(error) {
            alert(error);
        }

    }

    static async createProjectFavorites(data) {

        try {
            let response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/projectfavorites", 
                data,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )

            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async updateProjectFavorites(data) {

        try {
            let response = await axios.patch(
                process.env.REACT_APP_SERVER_URL + "/projectfavorites", 
                data,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )

            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }
}
