import React, { useState, useRef } from 'react';
import Refresh from "../../../assets/svg/Navigation/Refresh.svg"
import BackArrow from "../../../assets/svg/Navigation/BackArrow.svg"

const IconSwitcher = (props) => {
    const iconRef = useRef([
        { id: 0, name: 'Arrow Down', action: () => props.swipeUp(currentIconIndex), icon: <img src={BackArrow} className="Icon-Mid IconNoSelect" style={{rotate: "-90deg"}} alt={"Arrow Down"} onContextMenu={(e) => e.preventDefault()}/> },
        { id: 1, name: 'Refresh', action: () => { props.onClick(currentIconIndex) }, icon: <img src={Refresh} className="Icon-Mid IconNoSelect" alt={"Refresh"} onContextMenu={(e) => e.preventDefault()}/> },
        { id: 2, name: 'Arrow Up', action: () => props.swipeDown(currentIconIndex), icon: <img src={BackArrow}  className="Icon-Mid IconNoSelect" style={{rotate: "90deg"}} alt={"Arrow Up"} onContextMenu={(e) => e.preventDefault()}/> },
    ])
    const [currentIconIndex, setCurrentIconIndex] = useState(0);
    const tapTimer = useRef(null);
    const isLongPress = useRef(false); // Flag to track long press

    const handleMouseDown = (e) => {
        e.preventDefault(); // Prevent default behavior if necessary
        isLongPress.current = false; // Reset long press flag

        tapTimer.current = setTimeout(() => {
            isLongPress.current = true;
            iconRef.current[currentIconIndex].action();
        }, 500); // Long press duration
    };
    
    const handleMouseUp = (e) => {
        e.preventDefault(); // Prevent default behavior if necessary
        clearTimeout(tapTimer.current);
        
        // If it was not a long press, switch the icon
        if (!isLongPress.current) {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconRef.current.length);
        }
    };
      
    return (
        <div
            className="icon-switcher"
        >
            <button
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                className="icon-button"
            >
                {iconRef.current[currentIconIndex].icon}
            </button>
        </div>
    );
};

export default IconSwitcher;
