import React, {useEffect, useState} from 'react'
import SearchField from './atoms/SearchField';

import ColumnMenu from './atoms/ColumnMenu';
import LogoAndTitle from "../../assets/svg/LogoAndTitle.svg";
import PricingDashboard from './PricingDashboard';
import InstallAppButton from './atoms/InstallAppButton';

function Header(props) {

    const {icon, onClickIcon, sign, endIcons, installApp} = props;

    const [menu, setMenu] = useState(false);

    const toggleMenu = () => {
        if(onClickIcon) {
            onClickIcon();
            setMenu(!menu);
        }
    }

    return (
        <div className='HeaderContainer'>
            <div className='Header'>
                <div className='start'>
                    
                    {
                        icon 
                        &&   
                        <img className={menu ? "MenuIcon Logo rotate" : "MenuIcon Logo"} src={icon} alt="Logo" onClick={toggleMenu} />
                    }
                    {
                        icon && sign && 
                        <div className='Sign'>
                            <h1 className='primary primaryFont first'>{sign.first}</h1>
                            <h1 className='primary second'>{sign.second}</h1>
                        </div>
                    }
                    <img src={LogoAndTitle} alt="Logo" />
                </div>
                <div className='end'>
                    {
                        endIcons 
                        && 
                        endIcons.map((value, key) => {

                            if(value.type === "premium") {
                                return <PricingDashboard key={key} /> 
                            }

                            if(value.type === "search")
                                return <SearchField key={key} list={value.list}/>

                            if(value.type === "button")
                                return <button key={key} onClick={value.onClick} className={value.changes ? 'button-secondary' : 'button-disabled'} disabled={!value.changes}><h4 className='noMargin'>{value.changes ? value.text : value.changeText }</h4></button>
                            return <img key={key} className="Icon" src={value.icon} alt={value.alt} />
                        })
                    } 
                    {
                        installApp && 
                        <InstallAppButton/>
                    }
                </div>
            </div>
            {
                props.subOptions &&
                <div className='SubHeader'>
                    <ColumnMenu 
                        options={props.subOptions}
                        active={props.subOptionActive}
                        setActive={props.setSubOptionActive}
                    />
                </div>
            }
      </div>
  )
}

export default Header