import React, {useEffect} from 'react'
import GoBackIcon from "../assets/svg/Navigation/BackArrow.svg"
import HomeIcon from "../assets/svg/Navigation/Home-active.svg"
import { useNavigate, useLocation } from 'react-router-dom';

function PrivacyPolicy() {

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f9f9f9",
        zIndex: "150",
        position: 'relative',
        overflowX: "hidden"
    }}>
        <div 
            style={{
                position: "fixed",
                backgroundColor: "#f9f9f9",
                width: "100vw",
                padding: "2vh 5vw",
                boxSizing: "border-box"
            }}
        >
            {
                state?.prevUrl ?
                
                <img className="Icon-Large Clickable"  src={GoBackIcon} alt="Go Back" onClick={(e) => {
                    e.preventDefault();
                    navigate(state?.prevUrl, { state: state?.goBack });
                }}/>

                :

                <img className="Icon-Large Clickable"  src={HomeIcon} alt="Go Home" onClick={(e) => {
                    e.preventDefault();
                    navigate("/home");
                }}/>

            }
        </div>
        <div
            style={{
                fontFamily: "Arial, sans-serif",
                lineHeight: "1.6",
                padding: "0 5vw 5vh 5vw",
                gap: "2vh",
                boxSizing: "border-box"
            }}
            className='DesktopPage'
        >
            <div style={{
                marginTop: "10vh",
                marginBottom: "5vh"
            }}>
                <h1 className='noMargin bold breakWord' style={{color: "#333"}}>Privacy Policy</h1>
                <br />
                <p className='noMargin breakWord' style={{color: "#555"}}><strong style={{color: "#333"}}>Effective Date:</strong> 13/09/2024 </p>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Introduction</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}><strong style={{color: "#333"}}>WanderBuilds</strong> is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal data in compliance with the General Data Protection Regulation (GDPR).</p>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Information We Collect</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}>We collect the following types of personal data:</p>
                <ul>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Personal Information:</strong> This includes your name, email address, and password when you create an account.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Usage Data:</strong> We may collect information about how you use our website and services, including your IP address, browser type, and access times.</li>
                </ul>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Purpose of Data Collection</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}>We process your personal data for the following purposes:</p>
                <ul>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Account Creation:</strong> To create and manage your user account.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Service Improvement:</strong> To analyze usage patterns and improve our services.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Communication:</strong> To send you updates, newsletters, and promotional materials (only if you have opted in).</li>
                </ul>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Legal Basis for Processing</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}>We process your personal data based on the following legal grounds:</p>
                <ul>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Consent:</strong> You have given us explicit consent to process your personal data for specific purposes.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Contractual Necessity:</strong> Processing is necessary for the performance of a contract with you.</li>
                </ul>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Data Retention</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}>We will retain your personal data only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Your Rights</h2>
                <p className='noMargin breakWord' style={{color: "#555"}}>Under the GDPR, you have the following rights regarding your personal data:</p>
                <ul>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Right to Access:</strong> You have the right to request copies of your personal data.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Right to Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Right to Erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Right to Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                </ul>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Data Security</h2>
                <p style={{color: "#555"}}> We are committed to ensuring that your personal data is secure. We have implemented appropriate technical and organizational measures to protect your data from unauthorized access, loss, or misuse.</p>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Changes to This Privacy Policy</h2>
                <p style={{color: "#555"}}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.</p>
            </div>

            <div style={{
                marginBottom: "5vh"
            }}>
                <h2 className='noMargin breakWord' style={{color: "#333"}}>Contact Us</h2>
                <p style={{color: "#555"}}>If you have any questions about this Privacy Policy, please contact us:</p>
                <ul>
                    <li style={{color: "#555"}}><strong style={{color: "#333"}}>Email: </strong>
                    <a
                        href={`mailto:info@wanderbuilds.com`}    
                    >
                        info@wanderbuilds.com
                    </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
  )
}

export default PrivacyPolicy