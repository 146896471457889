import React from 'react'

function FileInput(props) {
  return (
    <>
      <label for="fileUpload" className="custom-file-label">Select Image</label>
      <input 
        className="FileInput"
        type="file" 
        id="fileUpload" 
        name={props.name} 
        accept="image/png, image/jpeg" 
        onChange={(e) => {props.onChange(e.target.files[0])}} 
        disabled={props.disabled}
        data-max-size="5242880"
      />
    </>
  )
}

export default FileInput