import React from 'react'

function Loading(props) {

  if(props.type === "circular") {
    return  (
      <div className='LoadingContainer'>
        <div className='grey-stall circle'></div>
      </div>
    )
  }

  if(props.type === "component") {
    return  (
      <div className='LoadingContainer'>
        <div className='grey-stall'></div>
      </div>
    )
  }

  if(props.type === "page") {
    return (
      <div className='LoadingPage'>
          <div className="loader"></div>
      </div>
    )
  }

  return (
    <div className='LoadingContainer'>
        <div className="loader"></div>
    </div>
  )
}

export default Loading