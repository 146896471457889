import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class ProjectUserPlays {

    static async createProjectUserPlay(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/projectUserPlays", 
            data,
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}
