import React from 'react'

import Thumbnail from './Thumbnail'

function GameList(props) {

    return (
        <div className="GameList">
            {
                props.games.map((value, key) => {
                    return <Thumbnail key={key} value={value} />
                })
            }
        </div>
  )
}

export default GameList