import toastMessage from "./Toast";
import { Profanity } from '@2toad/profanity';

const profanity = new Profanity();

const Validation = {

    isObscene(value) {
        return profanity.exists(value)  // If there are any matches, it's obscene
    },
    username(username, suppress = false) {

        let valid = /^([a-zA-Z0-9._-]){1,20}$/;

        let match = String(username).match(valid);
        
        if(!match) {
            toastMessage("Username Properties:\n" +
                        "Min 5 and Max 20 Characters \n" +
                        "Lowercase, Uppercase or Numbers\n" +
                        "The dot(.), underscore(_) and hypen(-) are allowed\n"
                        ,
                        "warning");
            return match;
        }
        return match;
    },
    email(email, suppress = false) {

        let match = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if(!match) {
            if(!suppress)
                toastMessage(email ? email + " is not a valid email." : "Email Input Cannot be Empty", "error");
            return false;
        }

        return true;
    },
    passwords(one, two) {
        if(!one) {
            toastMessage("Please Enter your Password in the First Password Field", "error");
            return false;
        }
        
        if(one !== two) {
            toastMessage("Passwords do not match", "error");
            return false;
        }
        return true;
    },

}

export default Validation;