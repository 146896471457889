import React, { useEffect, useState } from "react";
import Download from "../../../assets/svg/Download.svg"

const InstallAppButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener("beforeinstallprompt", handler);

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
        };
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) return;

        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
            setIsInstalled(true);
            setDeferredPrompt(null);
        }
    };

    return (
        <>
            {!isInstalled && deferredPrompt && (
                <div style={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    padding: "0 5vw",
                    right: "0vw",
                    top:  "1.25vh",
                    zIndex: 102,
                }}>
                    <button 
                        className={"install-button"} 
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "5vw"
                        }}
                            onClick={handleInstallClick}>
                        <img className="Icon-Reduced" src={Download} alt={"Install App"} />
                        <p className="black tertiaryFont">Install App</p>
                    </button>
                </div>
            )}
        </>
    );
};

export default InstallAppButton;
