import React, {useState, useEffect, useRef} from 'react'
import Editor from '@monaco-editor/react';

import Files from "../../services/server/Files"

import X from "../../assets/svg/X.svg"
import Plus from "../../assets/svg/Plus.svg"

import Modal from "../components/entry/Modal"
import NewFile from "./NewFile"
import ContextMenu from './atoms/ContextMenu';

function FileView(props) {

    const editorRef = useRef();

    const [files, setfiles] = useState([]);
    const [activeFile, setActiveFile] = useState({});
    const [newFile, setNewFile] = useState(false);
    const [menuPosition, setMenuPosition] = useState(null);

    useEffect(() => {
        if(props.scripts) {
            setfiles(props.scripts);
        } 
    }, [props.scripts])

    useEffect(() => {
        setActiveFile(props.activeFile);
    }, [props.activeFile])

    function onMount(editor) {
        editorRef.current = editor;
        editor.focus();

        // Helper function to get the position relative to the viewport
        const getMenuPosition = (e) => {
            // Get the editor container's position
            const containerRect = editor.getContainerDomNode().getBoundingClientRect();
            
            // Calculate the correct position
            const top = e.event.posy - containerRect.top;
            const left = e.event.posx + containerRect.left;
            
            return { top, left };
        };

        // Disable default context menu
        editor.onContextMenu((e) => {
            e.event.preventDefault();
            setMenuPosition(getMenuPosition(e));
        });

        let touchStartTime, isSelectingText = false;


        // Handle touch start for mobile devices
        editor.onMouseDown((e) => {
            if (e.event.browserEvent.type === 'touchstart') {
                touchStartTime = new Date().getTime();
                isSelectingText = false;
            }
        });

        editor.onMouseMove((e) => {
            if (e.event.browserEvent.type === 'touchmove') {
                // Detect if the user is selecting text
                isSelectingText = true;
            }
        });

        // Optionally handle touchend event for better mobile support
        editor.onMouseUp((e) => {
            if (e.event.browserEvent.type === 'touchend') {
                const touchEndTime = new Date().getTime();
                const touchDuration = touchEndTime - touchStartTime;

                if (isSelectingText) {
                    // If text is being selected, allow native behavior
                    return;
                }

                // Only show the custom context menu if the touch lasted long enough (indicating a long press)
                if (touchDuration > 500) { // Adjust the time threshold as needed
                    const editorDomNode = editorRef.current.getDomNode();
                    if (editorDomNode) {
                        editorDomNode.blur();  // Prevent the keyboard
                    }
                    setMenuPosition(getMenuPosition(e));
                }
            }
        });

        // Prevent the default long-press context menu on iPhone
        editor.onDidBlurEditorWidget(() => {
            if (editorRef.current) {
                editorRef.current.getDomNode().style.webkitTouchCallout = 'none';
            }
        });

        // Fix text selection on touch devices
        editor.onDidFocusEditorWidget(() => {
            if (editorRef.current) {
                editorRef.current.getDomNode().style.webkitTouchCallout = 'text';
            }
        });

    }

    const handleCopy = () => {
        const editor = editorRef.current;
        if (editor) {
        const selection = editor.getSelection();
        const text = editor.getModel().getValueInRange(selection);
        navigator.clipboard.writeText(text);
        }
        closeMenu();
    };

    const handlePaste = async () => {
        const editor = editorRef.current;
        if (editor) {
        const clipboardText = await navigator.clipboard.readText();
        editor.executeEdits('', [
            {
            range: editor.getSelection(),
            text: clipboardText,
            },
        ]);
        }
        closeMenu();
    };

    const handleCut = () => {
        const editor = editorRef.current;
        if (editor) {
        const selection = editor.getSelection();
        const text = editor.getModel().getValueInRange(selection);
        navigator.clipboard.writeText(text);

        // Remove the selected text
        editor.executeEdits('', [
            {
            range: selection,
            text: ''
            }
        ]);
        }
        closeMenu();
    };

    const handleUndo = () => {
        const editor = editorRef.current;
        if (editor) {
        editor.trigger('', 'undo', {});
        }
        closeMenu();
    };

    const handleRedo = () => {
        const editor = editorRef.current;
        if (editor) {
        editor.trigger('', 'redo', {});
        }
        closeMenu();
    };

    const handleSelectAll = () => {
        const editor = editorRef.current;
        if (editor) {
        const model = editor.getModel();
        editor.setSelection(model.getFullModelRange());
        }
        closeMenu();
    };

    const closeMenu = () => {
        setMenuPosition(null);
        editorRef.current.focus();
    };


    async function alterFile(value) {
        let updatedFiles = [...files]
        let fileToBeUpdated = updatedFiles.find((obj) => { return obj.id === activeFile.id});
        fileToBeUpdated.content = value;
        fileToBeUpdated.altered = true;
        setfiles(updatedFiles);
        let updatedFile = {...activeFile, content: value}
        await setActiveFile(updatedFile);
        await props.setScripts(updatedFiles);
    }

    function changeActiveFile(id) {
        setActiveFile(files.find((obj) => {return obj.id === id}))
    }

    async function addNewFile(value) {
        let newFile = {name: value, content: "", visible: true, language: "javascript", projectId:  props.projectId};
        let response = await Files.createFile(newFile);
        // save new file to db
        if(response) {
            let updatedFiles = [...files, response];
            await setfiles(updatedFiles);
            await setActiveFile(response);
            await props.setScripts(updatedFiles);
            await setNewFile(false);
        }
    }

    function hideFile(name) {
        let updateFiles = [...files]
        updateFiles.find((obj) => {return obj.name === name}).visible = false;
        setfiles(updateFiles);
        let visibleFile = updateFiles.find((value) => {
            return value.visible;
        })
        if(visibleFile) {
            setActiveFile({name: visibleFile.name, content: visibleFile.content});
        } else {
            setActiveFile(null);
            props.setActiveFile(null);
        }
    }

        return (
          <div className='ContentContainer'>
              <div className='Labels'>
                  {
                      files.map((value, key) => {
                        if(value.visible)
                            return (
                                <div className={activeFile.name === value.name ? 'Label active' : 'Label inactive'} key={key}>
                                    <h5 className='noMargin centerText'
                                    onClick={() => changeActiveFile(value.id)}>{value.name}</h5>
                                    <div className='ExitFile'>
                                        <img className="Tiny" src={X} alt="X" onClick={(e) => hideFile(value.name)} />
                                    </div>
                                </div>
                            )
                        return null
                      })
                  }
                  <div className="AddFile">
                    <img src={Plus} alt="Add File" onClick={() => setNewFile(true)}/>
                  </div>
              </div>
              { files.length >= 1 &&
              <div className='Files'>
                {
                activeFile &&   
                <div 
                    style={{ height: '80vh', position: 'relative' }}
                > 
                    <Editor 
                        height={"80vh"}
                        language='javascript'
                        value={activeFile.content} 
                        onChange={(value) => alterFile(value)}
                        onMount={onMount}
                        options={{
                            minimap: {
                            enabled: false,
                            },
                            fontSize: 12,
                            lineNumbersMinChars: "0",
                            padding: {
                                top: 20
                            },
                            contextmenu: false
                        }}
                    />
                    {menuPosition && (
                        <ContextMenu
                            top={menuPosition.top}
                            left={menuPosition.left}
                            onCopy={handleCopy}
                            onCut={handleCut}
                            onPaste={handlePaste}
                            onUndo={handleUndo}
                            onRedo={handleRedo}
                            onSelectAll={handleSelectAll}
                            onClose={closeMenu}
                        />
                    )}
                </div>
                }
                   { props.error && props.error.length >= 1 &&
                   <div className='Console'>
                        <div className='ConsoleTitle'>
                            <h3 className='noMargin'>Console</h3>
                            <img className="Tiny primary" src={X} alt="Close" onClick={() => props.setError()}/>
                        </div>
                        {
                            props.error.map((value, key) => {
                                return (
                                    <div className='ErrorMessage' key={key}>
                                        {value.file && <h4 className='noMargin normal'>File: {value?.file?.name}</h4>}
                                        <h4 className='noMargin breakWord normal'>{value?.message}</h4>
                                    </div>
                                )
                            })
                        }
                    </div>
                   } 
              </div>
              }
              <Modal title="New File" content={<NewFile setFileName={addNewFile}/>} show={newFile} setShow={setNewFile} />
            </div>
        )
}

export default FileView